<template>
  <el-row type="flex" justify="space-between">
    <click-to-edit
      v-model="scope.row.name"
      :placeholder="$companyConfiguration.i18n.t('card', 'section_element_name_column_placeholder')"
      :disabled="!isFieldAvailableForEdit(scope.row, 'name')"
      style="width: 100%"
      type="textarea"
      show-word-limit
      :word-limit="255"
      @input="saveElementPropChange(scope.row, 'name')"
    ></click-to-edit>
    <click-to-edit
      v-model="scope.row.desc"
      :placeholder="$companyConfiguration.i18n.t('card', 'section_element_desc_column_placeholder')"
      :disabled="!isFieldAvailableForEdit(scope.row, 'desc')"
      type="button-modal"
      modal-title="Описание"
      @input="saveElementPropChange(scope.row, 'desc')"
    >
      <el-button
        slot="reference"
        class="table-action-button open-desc-button"
        :class="{'has-desc': !!scope.row.desc}"
        type="text"
        round
        size="mini"
        icon="fas fa-info-circle"
      ></el-button>
    </click-to-edit>
  </el-row>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
export default {
  name: 'element-name-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {

    }
  },
  computed: {},
  methods: {

  }
}

</script>

<style scoped lang="scss">
@import "./src/assets/css/project-variables";

.open-desc-button {
  color: gray;

  &.has-desc{
    color: $--color-primary;
  }
}

</style>
