<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="show"
      :width="width"
      append-to-body
    >

      <template v-slot:title>
        <div style="display: flex; justify-content: space-between">
          <div>{{title}}</div>

          <el-button
            style="margin-right: 50px; margin-top: -4px;"
            type="text"
            size="mini"
            icon="fas fa-file-excel"
            @click="getImportExample"
          >
            Скачать пример
          </el-button>
        </div>
      </template>




      <el-upload
        v-loading="loading"
        ref="file-uploader"
        class=""
        action=""
        :limit="2"
        :file-list="fileList"
        :on-change="changeFileList"
        :auto-upload="true"
        :show-file-list="false"
        :http-request="doFileUpload"
      >
<!--        <i class="el-icon-upload" style="margin-top: 40px"></i>-->
<!--        <div class="el-upload__text">Перетащите или нажмите для загрузки файла</div>-->
        <el-button size="small" type="primary">Выберите файл для загрузки</el-button>
      </el-upload>

      <div
        v-if="errors.length"
        style="margin-top: 30px"
      >
        Ошибки:
        <div v-for="error in errors">{{error}}</div>
      </div>




      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
      </div>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";
import {downloader} from "@/mixins/downloader";

export default {
  name: "import-modal",
  mixins: [downloader],
  components: {},
  props: {
    title: {type: String, default: 'Импорт'},
    width: {type: String, default: '40%'},

    entity: {type: String, required: true},

    closeOnSuccess: {type: Boolean, default: false},
  },

  data() {
    return {
      show: false,
      loading: false,

      fileList: [],

      errors: [],
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal() {
      this.show = true;
    },


    getImportExample() {
      this.downloadFile(
        false,
        this.$api.exportImport.getImportExample,
        {entity: this.entity}
      )
    },


    changeFileList() {
      this.fileList = this.$refs["file-uploader"].uploadFiles
      if(this.fileList.length === 2) this.fileList.splice(0, 1)
    },


    doFileUpload() {
      this.loading = true;

      let form = new FormData();
      form.append('file', this.fileList[0].raw);
      form.append('entity', this.entity);

      return this.$api.exportImport.import(form)
        .then(data => {

          this.errors = data.errors;

          if( !this.errors.length ){
            this.$emit('import-succeed');
            if( this.closeOnSuccess ){
              this.show = false;
            }
          }

          if( data.response ){
            this.$emit('on-response', data.response);
          }

        })
        .finally(() => {
          this.loading = false;
          this.$emit('import-finished');
        })
    },




  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }

}
</script>

<style>

</style>