<template>
  <el-dialog
    title="Редактирование шкалы цели"
    :visible.sync="show"
    width="50%"
    modal-append-to-body
    append-to-body
  >

    <component
      ref="elementScale"
      :is="scaleEditTemplate"
      :disabled="editDisabled"
      :data="element.scale"
      :scale-name="element.scale_type"
      :scale-config="scaleEditConfig"
    ></component>

    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">Отмена</el-button>
      <el-button
        v-if="!editDisabled"
        type="primary"
        size="small"
        :loading="loading"
        @click="saveScale"
      > Сохранить
      </el-button>
    </div>

  </el-dialog>
</template>

<script>


export default {
  name: "card-element-scale-edit-dialog",
  components: {},
  props: {},

  computed: {
    scaleEditTemplate() {
      if( !this.element.scale_type ){
        return null;
      }
      return this.$companyConfiguration.scales.getScaleTemplate(this.element.scale_type, 'cardEditForm');
    },
    scaleEditConfig() {
      if( !this.element.scale_type ){
        return null;
      }
      return this.$companyConfiguration.scales.getScaleConfig(this.element.scale_type);
    },
    editDisabled: function (){
      return !this.element.fields_permissions || !this.element.fields_permissions.scale;
    }
  },
  data() {
    return {
      show: false,
      loading: false,

      element: {
        id: null,
        scale_type: null,
        scale_id: null,
        scale: {},
        fields_permissions: {},
      },

    }
  },
  mounted() {

  },
  methods: {
    open(element) {
      this.element = Object.assign({}, element);
      this.show = true;
    },
    close() {
      this.loading = false;
      this.show = false;
    },
    saveScale() {
      this.$refs.elementScale.$refs.editForm.validate((result)=> {
        if (!result) {
          return;
        }
        this.loading = true;
        this.element.scale = this.$refs.elementScale.scale;
        this.$emit('save-clicked', this.element);
      });

    },
  }
}
</script>

<style>

</style>