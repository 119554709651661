<template>
    <span>
        <el-dialog
          append-to-body
          title="Сохранение отчета"
          :visible.sync="show"
          width="40%"
        >
            <el-row>
                <el-form
                  v-loading="loading"
                  size="small"
                  label-position="top"
                >
                    <el-form-item label="Название отчета">
                        <el-input maxlength="255" show-word-limit v-model="name"></el-input>
                    </el-form-item>
                  <!--                    <el-form-item label="Доступ">-->
                  <!--                        <el-radio-group v-model="access">-->
                  <!--                            <el-radio :label="1" border>{{$constants.savedReportAccessNames[1]}}</el-radio>-->
                  <!--                            <el-radio :label="2" border>{{$constants.savedReportAccessNames[2]}}</el-radio>-->
                  <!--                        </el-radio-group>-->
                  <!--                    </el-form-item>-->
                </el-form>
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="show = false">Отмена</el-button>
                <el-button type="primary" size="small" :loading="loading" @click="saveReport">Сохранить</el-button>
            </span>
        </el-dialog>

        <span ref="wrapper">
            {{ report.name }}
            <slot name="reference"></slot>
        </span>
    </span>
</template>

<script>
import {off, on} from "element-ui/src/utils/dom";

export default {
  name: "save-report-dialog",
  components: {},

  props: {
    report: Object
  },

  data() {
    return {
      loading: false,
      show: false,

      access: null,
      name: null,

    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {

    showModal() {
      this.name = this.report.name;
      this.access = this.report.access;
      this.show = true;
    },


    async saveReport() {
      this.loading = true;
      let savedReportData = await this.$api.report.saveReport({
        id: this.report.entity_id,
        access: this.access,
        name: this.name,
      });

      let reportData = {
        entity_type: this.$constants.visualQueryBuilder.formEntityTypes.TYPE_REPORT,
        entity_id: savedReportData.report.id,

        elements: this.report.elements,
        selected_fields: this.report.selectedFields,
        relations: this.report.relations,
        search_fields: this.report.searchFields,
      };
      this.$api.report.saveVisualQueryBuilderForm(reportData)
        .then((data) => {
            this.$notify.success({
                title: 'Сохранено',
                message: 'Отчет успешно сохранен'
            });
            this.$emit('report-saved', reportData);
        })
        .finally(()=>{
            this.loading = false;
            this.show = false;

        })
    }

  },
  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }
}
</script>

<style>

</style>