<template>
    <el-row type="flex" class="row-bg" justify="space-between" style="margin-bottom:10px">
        <div>
<!--          <span style="width: 20px; height: 25px; overflow: hidden; display: inline-block; position: relative">-->
<!--            <img-->
<!--              style="position: absolute; bottom:-1px"-->
<!--              height="25"-->
<!--              src="@/assets/img/logo_new.svg"-->
<!--            />-->
<!--          </span>-->

            <span class="page-heading" >
                <el-skeleton
                    style="display: inline-block;"
                    :loading="!heading"
                    :rows="1"
                    animated
                >
                    <template slot="template">
                        <el-skeleton-item variant="text" style="height: 25px;width:400px" />
                    </template>
                    <template>
                        {{ heading }}
                    </template>
                </el-skeleton>
            </span>

<!--            <el-button-->
<!--                class="table-action-button"-->
<!--                type="text"-->
<!--                round-->
<!--                size="mini"-->
<!--                icon="fas fa-info-circle"-->
<!--                @click="showHelp=true;"-->
<!--            ></el-button>-->


            <el-dialog
                title="Подсказка"
                :visible.sync="showHelp"
                width="30%"
            >
                <slot name="help"></slot>
            </el-dialog>
        </div>
        <div>
            <slot name="right"></slot>
        </div>
    </el-row>

</template>

<script>


export default {
    name: "page-heading",

    props: {
        heading: {
            type: String,
        },
        help: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            showHelp: false
        }
    },
    mounted() { },
    watch: { },
    methods: { },

}
</script>

<style scoped>
.page-heading {
    font-size: 30px;
}

.table-action-button {
    margin-left: 10px;
    font-size: 22px;
}
</style>