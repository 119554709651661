<template>

  <div>

    <component
      class="auth-method-component"
      :is="methodTemplate"
    ></component>

  </div>

</template>

<script>


export default {
  name: "auth-method",

  props: {
    methodName: {type: String, required: true},
  },

  computed: {
    methodTemplate() {
      return this.$companyConfiguration.auth.getAuthMethodTemplate(this.methodName);
    }
  },

  data() {
    return {
    }
  },

  methods: {},
}
</script>

<style>
.auth-method-component {
  margin-bottom: 30px;
}
</style>