import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getNavigationData(payload){
            return requestSender('get', 'home/navigation-data', payload);
        },
        getChartData(payload){
            return requestSender('get', 'home/chart-data', payload);
        },

    }
}