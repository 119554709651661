import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        login(payload){
            return requestSender('post', 'auth/login', payload);
        },
        sendRestoreLink(payload){
            return requestSender('post', 'auth/send-restore-link', payload);
        },
        setNewPassword(payload){
            return requestSender('post', 'auth/set-new-password', payload);
        },

    }
}