<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>


<script>
import { mapGetters } from 'vuex';
import DefaultLayout from "./layouts/DefaultLayout";
import EmptyLayout from "./layouts/EmptyLayout";
import {RefreshClientOnUpdateMixin} from "@/mixins/RefreshClientOnUpdateMixin";


export default {
  name:"app",
  mixins: [RefreshClientOnUpdateMixin],
  components: {DefaultLayout, EmptyLayout},
  computed: {
    ...mapGetters([ 'user' ]),
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  },

  mounted() {
    this.initAppVersionCheck();
  },

  methods: {

  }

}
</script>

<style lang="scss">
//@import url("../node_modules/@fortawesome/fontawesome-free/css/all.css");
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "assets/css/fonts.css";
@import "assets/css/style.scss";
</style>
