<template>


  <el-row :gutter="5">
    <el-col :span="6" style="border-right: 1px solid #E4E6EC; padding-right:0">
      <library-folders
        :key="updateKey"
        :library-type="type"
        :mode-select="modeSelect"
        :from-card="fromCard"
        @folder-selected="folderSelected"
      >
      </library-folders>
    </el-col>
    <el-col :span="18" style="margin-left:-1px;border-left: 1px solid #E4E6EC; padding-left:10px">
      <library-elements
        v-if="$canAndRulesPass(user, $permissions.LIBRARY.ELEMENT_LIST)"
        :key="updateKey"
        :library-type="type"
        :current-folder="currentFolder"
        :mode-select="modeSelect"
        @selection-changed="$emit('selection-changed', $event)"
      ></library-elements>
    </el-col>
  </el-row>


</template>

<script>

import LibraryFolders from "@/components/library/LibraryFolders";
import LibraryElements from "@/components/library/LibraryElements";
import {mapGetters} from "vuex";
export default {
  name: 'library-manager',
  components: {LibraryElements, LibraryFolders},

  props: {
    type:{
      type: String,
      required: true
    },
    modeSelect: { type: Boolean, default: false },
    fromCard: { type: Number },
  },

  watch: {
    type: function (){
      this.updateKey++;
    }
  },

  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      updateKey: 0,
      currentFolder: null,
    }
  },
  mounted() {

  },
  methods: {
    folderSelected(folder) {
      this.currentFolder = folder;
    },
  }
}

</script>