<template>
  <el-dialog
    :title="'Пользователи роли ' + role.description"
    :visible.sync="show"
    width="70%"
  >
    <el-row v-loading="loading">
      <users-table
        add-button-text="Добавить пользователей"
        search-field
        :users.sync="users"
        :add-users-filter-role="role.add_from_roles_filter"
        @search="searchUsers"
        @users-added="assignUsersToRole"
        @user-deleted="revokeUserFromRole"
      ></users-table>
    </el-row>

    <paginator
      :paginator.sync="paginator"
      @page-changed="searchUsers(search)"
    ></paginator>

    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="show = false">Закрыть</el-button>
    </div>
  </el-dialog>
</template>

<script>


import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator";
import UsersTable from "@/components/user/UsersTable";
import UserGroupSelect from "@/components/filters/users/UserGroupSelect.vue";
import {mapGetters} from "vuex";

export default {
  name: "rbac-role-users-dialog",
  mixins: [paginator],
  components: {UserGroupSelect, UsersTable, Paginator},
  props: {},
  computed: {
    ...mapGetters([ 'user' ]),
  },
  data() {
    return {
      show: false,
      loading: false,

      search: null,
      groupIds: [],

      role: {},
      users: [],
    }
  },
  mounted() {

  },
  methods: {
    open(role) {
      this.role = role;
      this.searchUsers();
      this.show = true;
    },
    close() {
      this.show = false;
    },

    searchUsers(search) {
      this.loading = true;
      this.$api.user.search({
        search: search,
        roles: [this.role.name],

        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
        .then(({data, paginator}) => {
          this.users = data.users;
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },


    assignUsersToRole(users) {
      this.loading = true;
      let userIds = this.users.map(user => user.id);
      this.$api.rbac.assignUsersToRole({
        role_name: this.role.name,
        user_ids: userIds,
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Пользователи успешно добавлены'
          });
        })
        .finally(() => {
          this.loading = false;
          this.searchUsers();
        })
    },

    revokeUserFromRole(user) {
      this.loading = true;
      this.$api.rbac.revokeUsersFromRole({
        role_name: this.role.name,
        user_ids: [user.id],
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Пользователь успешно удален'
          });
        })
        .finally(() => {
          this.loading = false;
          this.searchUsers();
        })
    },

  }
}
</script>

<style>

</style>