export const downloader = {
    data: () => ({
        loadingDownloader: false,
    }),
    methods: {
        downloadFile(name, action, payload, cb){
            this.loadingDownloader = true;
            return action(payload, {responseType: 'blob'})
                .then(({data, headers}) => {
                    this.parseDownloadedResult(name, data, headers, cb);
                })
                .finally(() => {
                    this.loadingDownloader = false;
                })

        },

        parseDownloadedResult(name, data, headers, cb){
            let fileLink = document.createElement('a');
            fileLink.href = window.URL.createObjectURL(new Blob([data]));
            if( name === false ){
                name = headers["content-disposition"].split("filename");
                if( name[2] && name[2].includes('*=utf-8\'\'') ){
                    name = decodeURI(name[2].replaceAll('*=utf-8\'\'', ''))
                } else {
                    name = name[1].replace('="', '').replaceAll('"', '')
                }
            }
            fileLink.setAttribute('download', name);
            document.body.appendChild(fileLink);
            fileLink.click();

            if (typeof cb === 'function') {
                cb();
            }
        }

    }
}