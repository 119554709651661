<template>
  <div class="card-section">
    <div class="card-section-header">
      <div class="card-section-header-name">
        {{ $companyConfiguration.cardSections.getSectionConfig(section.type).showName }}
      </div>


      <el-button
        style="margin-left:30px"
        size="mini"
        icon="fas fa-plus"
        @click="addElement"
      >
        {{ $companyConfiguration.i18n.t('card', 'section_add_element_button') }}
      </el-button>
    </div>

    <el-table
      class="card-section-elements-table"
      :class="{['section-'+section.type]: true}"
      :show-header="!!section.elements.length"
      style="width: 100%"
      border
      size="mini"
      :data="section.elements"
      empty-text="Нет целей"
      v-loading="loading"
    >
      <component
        v-for="(columnConfig, index) in columnsConfigs"
        v-if="columnConfig.getVif(thisComponent)"
        :is="columnConfig.manualColumnTemplate ? columnConfig.template : baseMatrixTableColumn"
        :key="index"
        :column-config="columnConfig"
        v-bind="columnConfig.getBind(thisComponent)"
        @remove-card="$emit('remove-card', $event)"
        @element-changed="saveElementPropChange"
        @element-deleted="deleteElement"
      >
      </component>

    </el-table>


    <element-add-from-library-dialog
      ref="addElementFromLibraryDialog"
      @element-ids-selected="createElementsFromLibrary"
    >
    </element-add-from-library-dialog>

  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit";
import CardElementScaleEditDialog from "@/components/cards/CardElementsBlock/CardElementScaleEditDialog";
import ElementAddFromLibraryDialog from "@/components/cards/CardElementsBlock/ElementAddFromLibraryDialog";
import ElementNameTableColumn from "@/components/tableColumnTemplates/card/ElementNameTableColumn";
import {mapGetters} from "vuex";

export default {
  name: "matrix-section",
  components: {
    ElementNameTableColumn, ElementAddFromLibraryDialog, CardElementScaleEditDialog, ClickToEdit
  },

  props: {
    section: {
      type: Object,
      required: true,
    },
    cards: {
      type: Object,
      required: true,
    },
    scroll: {
      type: Number,
      default: 0,
    },
  },

  watch: {
    scroll: function(newVal, oldVal) {
      let el = document.querySelector('.card-section-elements-table.section-'+this.section.type+' .el-table__body-wrapper');
      el.scrollLeft = newVal;
      el = document.querySelector('.card-section-elements-table.section-'+this.section.type+' .el-table__header-wrapper');
      el.scrollLeft = newVal;
    }
  },

  computed: {
    baseMatrixTableColumn() {
      return () => import(`@/components/tableColumnTemplates/BaseMatrixTableColumn`);
    },
  },
  data() {
    return {
      thisComponent: this,
      loading: false,

      columnsConfigs: [],
    }
  },
  beforeMount() {
    this.columnsConfigs = this.$companyConfiguration.helpers.getTableColumnsArrayFromConfig(
      this.$companyConfiguration.matrix.getSectionColumns(this.section.type)
    );
  },
  mounted() {
    let el = document.querySelector('.card-section-elements-table.section-'+this.section.type+' .el-table__body-wrapper');
    el.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    addElement() {
      let fromLibraryConfig = this.$companyConfiguration.cardSections.getSectionConfig(this.section.type)['fromLibrary'];
      if (fromLibraryConfig) {
        this.addElementFromLibrary(fromLibraryConfig);
      } else {
        this.createElement();
      }
    },

    createElement() {
      this.loading = true;
      let data = {
        data: {
          section_ids: this.section.ids,
        }
      }
      // autofill scale_type if only one in system
      const allowedScaleTypesForSection = this.$companyConfiguration.cardSections.getAllowedScaleTypesForSections(this.section.type);
      if( allowedScaleTypesForSection.length === 1 ){
        data.scale_type = allowedScaleTypesForSection[0];
      }

      this.$api.matrix.update(data)
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Элемент успешно сохранен'
          });

          data.elements.forEach(newElement => {
            this.section.elements.push(newElement);
          })

          this.checkDataForErrors(data);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    addElementFromLibrary(libraryType) {
      this.$refs.addElementFromLibraryDialog.open(libraryType);
    },
    createElementsFromLibrary(libraryElementIds) {
      this.loading = true;
      this.$api.matrix.update({
        data: {
          section_ids: this.section.ids,
          library_element_ids: libraryElementIds
        }
      })
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Элементы успешно сохранены'
          });

          data.elements.forEach(newElement => {
            this.section.elements.push(newElement);
          })

          this.checkDataForErrors(data);
        })
        .finally(() => {
          this.loading = false;
          this.$refs.addElementFromLibraryDialog.close();
        })
    },


    isFieldAvailableForEdit(element, fieldName) {
      return element.fields_permissions && element.fields_permissions[fieldName];
    },

    saveElementPropChange({element, prop, value}) {
      this.loading = true;

      let data = {};
      data[prop] = value;

      this.$api.matrix.update({
        element_ids: element.ids,
        data: data,
      })
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Элемент успешно сохранен'
          });

          const oldElementIndex = this.section.elements.findIndex(el => el === element);
          this.section.elements.splice(oldElementIndex, 1);

          data.elements.forEach(newElement => {
            this.section.elements.splice(oldElementIndex, 0, newElement);
          })

          this.checkDataForErrors(data);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    deleteElement(element) {
      this.loading = true;
      this.$api.matrix.delete({
        element_ids: element.ids,
      })
        .then((data) => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Элемент успешно удален'
          });
          const oldElementIndex = this.section.elements.findIndex(el => el === element);
          this.section.elements.splice(oldElementIndex, 1);

          data.elements.forEach(newElement => {
            this.section.elements.splice(oldElementIndex, 0, newElement);
          })

          this.checkDataForErrors(data);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    checkDataForErrors(data){
      if( !data.skipped_cards || !data.skipped_cards.length ){
        return;
      }

      let cardFios = [];
      data.skipped_cards.forEach(id => {
        cardFios.push(this.cards[id].user.full_name);
      })

      this.$alert(
        'Из-за ограничения полномочий в карты следующих ' +
        'сотрудников не были внесены изменения:<br><br>' + cardFios.join('<br>'),
        'Внимание',
        {
          confirmButtonText: 'Закрыть',
          dangerouslyUseHTMLString: true
        }
      );
    },

    handleScroll(event){
      this.$emit('update:scroll', event.target.scrollLeft);
    },
  }
}
</script>


<style scoped lang="scss">
.card-section {
  margin-bottom: 30px;

  &-header {
    font-weight: bold;
    margin-bottom: 5px;

    &-name {
      display: inline-block;
      min-width: 100px;
    }
  }
}

</style>