import Api from "@/api/base";


export default {
    namespaced: true,

    state: {
        loading: false,
        stages: [],
        currentStage: null,
        currentNegotiation: null,
    },
    getters: {
        loading(state) {
            return state.loading
        },
        stages(state) {
            return state.stages;
        },

        currentStage(state) {
            return state.currentStage;
        },
        currentNegotiation(state) {
            return state.currentNegotiation;
        },

    },
    actions: {
        loadStages(ctx, payload) {
            if( !ctx.rootState.card.card.id ){
                return;
            }
            ctx.commit('setLoading', true);
            Api.card.getCardStages({
                card_id: ctx.rootState.card.card.id,
                expand: 'permissions, negotiations, negotiations.user, negotiations.permissions'
            })
                .then(data => {
                    let currentStage = data.stages.find(s => s.id === ctx.rootState.card.card.current_stage_id);
                    const currentNegotiation = currentStage
                        ? currentStage.negotiations.find(n => n.id === currentStage.current_negotiation_id)
                        : null;
                    ctx.commit('updateStages', data.stages);
                    ctx.commit('setCurrentStage', currentStage)
                    ctx.commit('setCurrentNegotiation', currentNegotiation)
                })
                .finally(() => {
                    ctx.commit('setLoading', false);
                })
        },

        setStageDates(ctx, stage) {
            ctx.commit('setLoading', true);
            return Api.card.setStageDates({
                stage_id: stage.id,
                date_start: stage.date_start,
                date_end: stage.date_end,
            })
                .then(data => {

                })
                .finally(() => {
                    ctx.commit('setLoading', false);
                })
        },

        setActiveNegotiation(ctx, {negotiation, sendNotify}) {
            ctx.commit('setLoading', true);
            return Api.card.setCurrentNegotiation({
                negotiation_id: negotiation.id,
                mute_notification: !sendNotify,
            })
                .then(data => {
                    return data;
                })
                .finally(() => {
                    ctx.dispatch('card/loadCard', null, {root: true});
                })
        },

        saveNegotiation(ctx, negotiation) {
            ctx.commit('setLoading', true);
            return Api.card.saveNegotiation(negotiation)
                .then(data => {
                    return data;
                })
                .finally(() => {
                    ctx.dispatch('loadStages');
                })
        },

        deleteNegotiation(ctx, negotiation) {
            ctx.commit('setLoading', true);
            return Api.card.deleteNegotiation({
                id: negotiation.id,
            })
                .then(data => {
                    return data;
                })
                .finally(() => {
                    ctx.dispatch('loadStages');
                })
        },

        saveMovedNegotiation(ctx, {movedNegotiation, newPosition}) {
            ctx.commit('setLoading', true);
            return Api.card.setStageNegotiationOrder({
                negotiation_id: movedNegotiation.id,
                order: newPosition,
            })
                .then(data => {
                    return data;
                })
                .finally(() => {
                    ctx.dispatch('loadStages');
                })
        },



        startDynamicStage(ctx, stage) {
            ctx.commit('setLoading', true);
            return Api.card.startDynamicStage({
                card_id: stage.card_id,
                stage_type: stage.type,
            })
                .then(data => {
                    return data;
                })
                .finally(() => {
                    ctx.dispatch('loadStages');
                })
        },

        deleteStage(ctx, stage) {
            ctx.commit('setLoading', true);
            return Api.card.deleteStage({
                stage_id: stage.id,
            })
                .then(data => {
                    return data;
                })
                .finally(() => {
                    ctx.dispatch('loadStages');
                })
        },


        unloadStages(ctx){
            ctx.commit('updateStages', []);
            ctx.commit('setCurrentStage', null);
            ctx.commit('setCurrentNegotiation', null);
        },
    },

    mutations: {
        setLoading(state, isLoading) {
            state.loading = isLoading;
        },

        updateStages(state, stages) {
            state.stages = stages;
        },

        setCurrentStage(state, stage) {
            state.currentStage = stage;
        },
        setCurrentNegotiation(state, negotiation) {
            state.currentNegotiation = negotiation;
        },
    }
}