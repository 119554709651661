const Constants = {
    comment: {
        entityTypes: {
            TYPE_CARD: 'card',
            TYPE_CARD_ELEMENT: 'card_element',
        }
    },

    user: {
        subordinates:{
            SUBORDINATES_NONE: null,
            SUBORDINATES_DIRECT: 1,
            SUBORDINATES_ALL: 2,
        }
    },

    stage:{
        types: {
            TYPE_PLAN: 'plan',
            TYPE_CORRECTION: 'correction',
            TYPE_INTERMEDIATE_ASSESSMENT: 'intermediate_assessment',
            TYPE_ASSESSMENT: 'assessment',
        }
    },

    card: {
        statuses: {
            STATUS_COMPLETED: 200,
        }
    },

    library: {
        folderVisibilityConfigMode: {
            MODE_WHITELIST: 1,
            MODE_BLACKLIST: 2,
        },
        elementChangePropagationModes: {
            MODE_ALL_CARDS: 1,
            MODE_NOT_FINISHED_CARDS: 2,
        }
    },

    visualQueryBuilder: {
        formEntityTypes: {
            TYPE_REPORT: 'report',
            TYPE_LIBRARY_FOLDER: 'library_folder',
            TYPE_PREPROCESSOR_ITEM: 'preprocessor_item',
        }
    },

}

export default {
    install(Vue) {
        Vue.prototype.$constants = Constants;
    },

}

export { Constants }