import requestSender from "./base/requestSender";

export default function (axiosInstance) {
    return {
        jobs(payload) {
            return requestSender('get', 'mass-action/jobs', payload);
        },
        create(payload) {
            return requestSender('post', 'mass-action/create', payload);
        },

    }
}