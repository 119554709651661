<template>

  <div>

    <page-heading
      heading="Карты"
    >
      <span slot="help"></span>
      <div slot="right">
        <div class="el-row--flex">
          <deleted-cards-modal
            v-if="$canAndRulesPass(user, $permissions.CARD.LIST_DELETED)"
            @cards-restored="loadCards"
          >
            <el-button

              slot="reference"
              size="small"
              icon="fas fa-trash"
            >
              Удаленные карты
            </el-button>

          </deleted-cards-modal>


          <el-button
            v-if="$canAndRulesPass(user, $permissions.CARD.CREATE)"
            style="margin-left:10px"
            type="primary"
            size="small"
            icon="fas fa-plus"
            @click="cardEditModalShow()"
          >
            {{ $companyConfiguration.i18n.t('cards', 'add_cards_button') }}
          </el-button>
        </div>
      </div>
    </page-heading>


    <el-card>
      <cards-component
        ref="cardsComponent"
      ></cards-component>
    </el-card>


    <card-edit-dialog
      ref="cardEditDialog"
      @card-saved="loadCards"
    ></card-edit-dialog>


  </div>

</template>

<script>
import PageHeading from "@/components/PageHeading";
import Paginator from "@/components/Paginator";
import CardEditDialog from "@/components/cards/CardEditDialog";
import {paginator} from "@/mixins/paginator";
import CardsPageFilters from "@/components/cards/CardsPageFilters";
import DeletedCardsModal from "@/components/cards/DeletedCardsModal";
import CardsComponent from "@/components/cards/CardsComponent";
import {mapGetters} from "vuex";

export default {
  name: 'Cards',
  components: {
    CardsComponent,
    DeletedCardsModal,
    CardsPageFilters, CardEditDialog, Paginator, PageHeading
  },
  mixins: [paginator],

  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      loading: false,
    }
  },
  mounted() {},
  methods: {

    cardEditModalShow(card) {
      this.$refs.cardEditDialog.open(card);
    },

    loadCards(){
      if( this.$refs.cardsComponent ){
        this.$refs.cardsComponent.loadCards();
      }
    },

  }
}

</script>


<style scoped lang="scss">
@import "./src/assets/css/project-variables";


</style>