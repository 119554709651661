<template>
  <div>
    <el-dialog
      title="Управление единицами измерения"
      :visible.sync="show"
      width="40%"
      top="2vh"

    >

      <div
        v-loading="loading"
      >
        <div
          v-for="unit in units"
          class="library-scales-unit-row"
        >
          <el-input
            v-model="unit.symbol"
            style="width: 100%"
            size="mini"
            show-word-limit
            maxlength="100"
            @change="saveUnit(unit)"
          ></el-input>

          <el-popconfirm
            v-if="unit.id"
            style="margin-left: 10px; margin-top:2px"
            confirm-button-text='Да'
            cancel-button-text='нет'
            icon="el-icon-question"
            title="Вы уверены, что хотите удалить единицу измерения?"
            @confirm="deleteUnit(unit)"
          >
            <el-button
              slot="reference"
              class="table-action-button"
              type="default"
              round
              size="mini"
              icon="fas fa-trash"
              :loading="loading"
            >
            </el-button>

          </el-popconfirm>
        </div>


        <el-button
          size="small"
          type="text"
          icon="fas fa-plus"
          @click="addUnit"
        >
          Добавить единицу измерения
        </el-button>
      </div>



      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show = false">Закрыть</el-button>
      </span>
    </el-dialog>

    <span ref="wrapper">
      <slot name="reference"></slot>
    </span>
  </div>
</template>

<script>

import {off, on} from "element-ui/src/utils/dom";


export default {
  name: "library-scales-units-modal",
  components: {},
  props: {},

  data() {
    return {
      loading: false,
      show: false,
      units: [],
    }
  },
  mounted() {
    if (this.$refs.wrapper.children) {
      on(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  },
  watch: {},
  methods: {
    showModal(){
      this.show = true;
      this.loadUnits();
    },

    loadUnits() {
      this.loading = true;
      this.$api.library.getScalesUnits()
        .then(data => {
          this.units = data.scales_units;
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    addUnit(){
      this.units.push({
        id: null,
        symbol: null,
      });
    },

    saveUnit(unit){
      this.loading = true;
      this.$api.library.saveScalesUnit(unit)
        .then(data => {
          unit.id = data.scales_unit.id;
          this.$notify.success({
            title: 'Сохранено',
            message: 'Единица измерения успешно сохранена'
          });
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    deleteUnit(unit){
      this.loading = true;
      this.$api.library.deleteScalesUnit({
        id: unit.id,
      })
        .then(data => {
          this.loadUnits();
          this.$notify.success({
            title: 'Удалено',
            message: 'Единица измерения успешно удалена'
          });
        })
    },
  },

  beforeDestroy() {
    if (this.$refs.wrapper.children) {
      off(this.$refs.wrapper.children[0], 'click', this.showModal);
    }
  }

}
</script>

<style>
.library-scales-unit-row{
  display: flex;
  margin-bottom: 5px;
}
</style>