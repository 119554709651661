<template>
  <div v-if="$canAndRulesPass(user, $permissions.USER.CHIEFS_LIST_BY_TYPE)">
    <page-heading
      heading="Настройка орг. структуры"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>

    <el-card
      v-for="chiefType in $companyConfiguration.user.getChiefTypes()"
      :key="chiefType"
      style="margin-bottom: 25px"
    >
      <chiefs-edit-component
        :type="chiefType"
      ></chiefs-edit-component>
    </el-card>

  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import ChiefsEditComponent from "@/components/user/ChiefsEditComponent.vue";
import {mapGetters} from "vuex";

export default {
  name: 'Chiefs',
  components: {ChiefsEditComponent, PageHeading},

  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {

    }
  },
  mounted() {},
  methods: {

  }
}

</script>


<style lang="scss">


</style>
