<template>
  <div class="cards-filters">
    <component
      v-model="filters"
      :is="filtersTemplate"
      :hidden="hidden"

      @change="filtersChanged"
    ></component>
  </div>
</template>

<script>


export default {
  name: "cards-page-filters",
  components: {},

  props: {
    value: {},
    hidden: {type: Array},
  },

  computed: {
    filtersTemplate() {
      return this.$companyConfiguration.card.getCardsPageFiltersTemplate();
    }
  },
  watch: {},
  data() {
    return {
      filters: {
        my_turn: null,
      },
    }
  },

  beforeMount() {
    this.filters.my_turn = this.$route.query.my_turn ? 1 : null;
  },

  methods: {
    filtersChanged(){
      this.$emit('input', this.filters);
      this.$emit('change', this.filters);
    }
  }
}
</script>



<style lang="scss">

</style>