<template>
  <el-button
    :size="size"
    :loading="loading"
    icon="fas fa-wrench"
    @click="agreeNegotiation"
  >
    <slot></slot>
  </el-button>
</template>

<script>

export default {
  name: "start-dynamic-stage-button",
  components: {},

  props: {
    size: {type: String, default: 'mini'},
    card: {type: Object, required: true},
    stageType: {type: String, required: true},
  },

  computed: {},
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
  },
  methods: {
    agreeNegotiation() {
      this.loading = true;
      this.$api.card.startDynamicStage({
        card_id: this.card.id,
        stage_type: this.stageType
      })
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Этап успешно сохранен'
          });
        })
        .finally(() => {
          this.loading = false;
          this.$store.dispatch('card/loadCard');
        })
    },
  }
}
</script>

<style>

</style>