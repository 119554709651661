export const ElementPropTableColumn = {
    props: {
        scope: {type: Object, required: true},
        isMatrix: {type: Boolean, default: false},
    },
    watch: {},
    mounted() {
    },

    methods: {
        isFieldAvailableForEdit(element, fieldName) {
            return element.fields_permissions && element.fields_permissions[fieldName];
        },

        saveElementPropChange(element, prop, value = null) {
            if (this.isMatrix) {
                this.$emit('element-changed', {
                    element: element,
                    prop: prop,
                    value: (value === null ? element[prop] : value)
                });
                return;
            }

            return this.$api.card.saveElementPropChange({
                id: element.id,
                prop: prop,
                value: value === null ? element[prop] : value,
            })
                .then((data) => {
                    this.$notify.success({
                        title: 'Сохранено',
                        message: 'Элемент успешно сохранен'
                    });

                    // Do not update whole row because there are extra fields used in table
                    element.bonus = data.element.bonus;
                })
                .finally(() => {
                    this.$emit('elements-changed');
                })
        },
    }
}