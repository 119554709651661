import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getNotificationsCount(payload){
            return requestSender('get', 'notification/get-notifications-count', payload);
        },
        getNotifications(payload){
            return requestSender('get', 'notification/get-notifications', payload);
        },
        markAsRead(payload){
            return requestSender('get', 'notification/mark-as-read', payload);
        },
        markAllAsRead(payload){
            return requestSender('get', 'notification/mark-all-as-read', payload);
        },

    }
}