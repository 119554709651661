<template>
    <component
      :is="cardHeaderBlockTemplate"
    ></component>
</template>

<script>

export default {
  name: "card-header-block",
  components: {},

  props: {},

  watch: {},
  computed: {
    cardHeaderBlockTemplate() {
      return this.$companyConfiguration.card.getCardHeaderTemplate();
    },
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>


<style lang="scss">

</style>