<template>
  <el-dialog
    title="Выбор цели"
    :visible.sync="show"
    width="90%"
  >

    <library-manager
      v-if="show"
      :type="libraryType"
      mode-select
      :from-card="fromCard"
      @selection-changed="selectionChanged"
    ></library-manager>

    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">Отмена</el-button>
      <el-button
        type="primary"
        size="small"
        :loading="loading"
        :disabled="!selectedElementIds.length"
        @click="addSelected"
      > Добавить отмеченные
      </el-button>
    </div>

  </el-dialog>
</template>

<script>

import LibraryManager from "@/components/library/LibraryManager";

export default {
  name: "element-add-from-library-dialog",
  components: {LibraryManager},
  props: {
    fromCard: { type: Number },
  },

  computed: {
    selectedElementIds(){
      return this.selectedElements.map(e=>e.id);
    }
  },
  data() {
    return {
      loading: false,
      show: false,
      libraryType: null,

      selectedElements: [],
    }
  },
  mounted() {

  },
  methods: {
    open(libraryType) {
      this.libraryType = libraryType;
      this.show = true;
    },
    close() {
      this.loading = false;
      this.show = false;
      this.selectedElements = [];
    },
    selectionChanged(elements){
      this.selectedElements = elements;
    },
    addSelected(){
      this.loading = true;
      this.$emit('elements-selected', this.selectedElements);
      this.$emit('element-ids-selected', this.selectedElementIds);
    }
  }
}
</script>

<style>

</style>