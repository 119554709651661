<template>

  <div>

    <div class="builder-container">

      <!--            <div>-->
      <!--                <div v-for="selectedField in selectedFields">{{selectedField}}</div>-->
      <!--            </div>-->
      <div v-show="showSelectedFieldsArea">
        <div
          class="dndrop-container horizontal selected-field-container"
          style="margin-bottom:20px;"
        >
          <div class="selected-field-container-hint" v-if="!selectedFields.length">
            <div style="font-size: 1.5rem">Нет колонок в отчете</div>
          </div>
          <div
            class="dndrop-draggable-wrapper"
            v-for="selectedField in selectedFields"
            :key="selectedField.alias+selectedField.field+Math.floor(Math.random() * 99999)"
          >
            <div class="selected-field-item">
              <div class="selected-field-item-relation">{{ selectedField.relationName }}</div>
              <div class="selected-field-item-name">{{ selectedField.name }}</div>
            </div>
          </div>
        </div>
      </div>


      <!--            <div>-->
      <!--                <div v-for="relation in relations">{{relation}}</div>-->
      <!--            </div>-->
      <div v-show="showElementsArea" class="selected-field-container" style="margin-bottom:20px;">
        <BuilderColumn
          :elements.sync="elements"
        ></BuilderColumn>

        <div class="selected-field-container-hint" v-if="!relations.length">
          <div style="font-size: 1.5rem; padding-top:20px">Зона выбора элементов отчета</div>
        </div>
      </div>


      <!--      <div>-->
      <!--          <div v-for="searchField in searchFields">{{searchField}}</div>-->
      <!--      </div>-->
      <div
        v-show="showSearchFieldsArea"
        style="position: relative"
      >
        <div
          class="dndrop-container horizontal selected-field-container"
          style="margin-bottom:20px;"
        >
          <div class="selected-field-container-hint" v-if="!searchFields.length">
            <div style="font-size: 1rem">Нет условий</div>
          </div>

          <div
            v-for="(searchField, fieldIndex) in searchFields"
            :key="fieldIndex"
          >

            <div v-if="searchField.nestedOr">
              <div class="selected-field-nested-or">
                <div style="font-weight: bold; padding:5px;">ИЛИ</div>
                <div
                  class="selected-field-container"
                  style="margin:5px;width:unset"
                >
                  <div
                    v-if="!searchField.searchFields.length"
                    class="selected-field-container-hint"
                    style="padding:10px"
                  >
                    <!--                    <div style="font-size: 1.5rem">Зона условий</div>-->
                    <div>Элементы, добавленные сюда, будут объединены в условии через ИЛИ</div>
                  </div>

                  <Draggable
                    v-for="(nestedSearchField, nestedFieldIndex) in searchField.searchFields"
                    :key="nestedFieldIndex"
                  >
                    <builder-selected-field-item
                      :search-field="nestedSearchField"
                      disabled
                    ></builder-selected-field-item>
                  </Draggable>


                </div>
              </div>
            </div>

            <builder-selected-field-item
              v-if="!searchField.nestedOr"
              disabled
              :search-field="searchField"
            ></builder-selected-field-item>

          </div>
        </div>
      </div>

    </div>

  </div>


</template>

<script>
import BuilderColumn from "./BuilderColumn";
import {Container, Draggable} from 'vue-dndrop';
import {applyDragForDndrop} from "@/utils/helpers";
import SaveReportDialog from "../SaveReportDialog";
import BuilderSelectedFieldItem from "@/components/report/builder/BuilderSelectedFieldItem.vue";

export default {
  name: "BuilderViewer",
  components: {BuilderSelectedFieldItem, SaveReportDialog, BuilderColumn, Container, Draggable},
  props: {

    elements: Array,
    selectedFields: Array,
    relations: Array,
    searchFields: Array,

    showSelectedFieldsArea: {type: Boolean, default: false},
    showElementsArea: {type: Boolean, default: false},
    showSearchFieldsArea: {type: Boolean, default: false},
  },
  computed: {},

  data() {
    return {

    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";

</style>