<template>
  <div style="display: inline-block" >

    <component
      v-if="menuTemplate"
      :is="menuTemplate"
    ></component>

    <el-menu
      v-else
      style="display: inline-block"
      mode="horizontal"
      router
      :default-active="$route.path"
    >

      <el-menu-item
        index="/"
        :route="{name: 'Home'}"
      >
        Главная
      </el-menu-item>


      <el-submenu
        v-show="$canAndRulesPass(user, $permissions.SETTING.UPDATE) || $canAndRulesPass(user, $permissions.RBAC.LIST)"
        index="1"
      >
        <template slot="title">Администрирование</template>

        <el-menu-item
          index="/settings/rbac"
          :route="{name: 'Rbac'}"
        >
          Настройка ролей
        </el-menu-item>
        <el-menu-item
          index="/settings/chiefs"
          :route="{name: 'Chiefs'}"
        >
          Настройка орг. структуры
        </el-menu-item>

        <el-menu-item
          v-show="$canAndRulesPass(user, $permissions.GROUP.LIST)"
          index="/settings/groups"
          :route="{name: 'Groups'}"
        >
          Настройка групп
        </el-menu-item>
        <el-menu-item
          index="/settings/mass-actions"
          :route="{name: 'MassActions'}"
        >
          Массовые действия
        </el-menu-item>
        <el-menu-item
          index="/settings/dynamic"
          :route="{name: 'DynamicSettings'}"
        >
          Прочее
        </el-menu-item>
      </el-submenu>

      <el-submenu
        v-show="$canAndRulesPass(user, $permissions.LIBRARY.SEE_IN_MENU)"
        index="2"
      >
        <template slot="title">Библиотеки</template>

        <el-menu-item
          v-for="(typeConfig, typeKey) in $companyConfiguration.libraries.getTypes()"
          :key="typeKey"
          :index="'/library/'+typeKey"
          :route="{name: 'Library', params: { type: typeKey }}"
        >
          {{ typeConfig.showName }}
        </el-menu-item>
      </el-submenu>

      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.CARD.LIST)"
        index="/cards"
        :route="{name: 'Cards'}"
      >
        Карты
      </el-menu-item>
      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.REPORT.USE)"
        index="/report"
        :route="{name: 'Report'}"
      >
        Конструктор отчетов
      </el-menu-item>
<!--            <el-menu-item index="/matrix" :route="{name: 'Matrix'}">Навигатор по целям подчинённых</el-menu-item>-->

    </el-menu>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "menu-component",
  components: {},

  props: {},

  watch: {},
  computed: {
    ...mapGetters(['user']),
    menuTemplate() {
      return this.$companyConfiguration.settings.getMenuTemplate();
    },
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>


<style lang="scss">

</style>