<template>
  <div v-loading="loading">
    <div v-if="!entityId" style="margin-top: 30px">
      Настройка доступна только на сохраненных объектах.
      Пожалуйста, сохраните объект и откройте настройки заново
    </div>
    <div v-if="entityId">
      <el-button
        style="float:right"
        size="mini"
        type="text"
        icon="fas fa-pen"
        @click="showEditDialog = true"
      >
        Редактировать
      </el-button>
      <div style="clear: both"></div>

      <builder-viewer
        v-if="reportForm.builderElements"
        :elements="reportForm.builderElements"
        :selected-fields.sync="reportForm.builderFields"
        :relations.sync="reportForm.builderRelations"
        :search-fields.sync="reportForm.builderSearch"

        show-search-fields-area

      ></builder-viewer>
    </div>



    <el-dialog
      title="Редактирование условий"
      append-to-body
      top="1vh"
      :visible.sync="showEditDialog"
      width="95%"
    >
      <el-row>
        <builder
          v-if="showEditDialog"
          :elements="reportForm.builderElements"
          :selected-fields.sync="reportForm.builderFields"
          :relations.sync="reportForm.builderRelations"
          :search-fields.sync="reportForm.builderSearch"
          :load-children="loadNodeRelations"

          :save-report-actions="false"
          :show-edit-mode-buttons="false"
          :show-selected-fields-area="false"
          :show-build-report-buttons="false"
          :click-node-field-to-select="false"

          @node-check-changed="nodeCheckChanged"
        ></builder>

        <report-result-modal
          :builder-fields="$companyConfiguration.massActions.getReportBuilderEntityPreviewSettings(entityReportKey).selected_fields"
          :builder-relations="reportForm.builderRelations"
          :builder-search="reportForm.builderSearch"
        >
          <el-button
            slot="reference"
            size="mini"
            type="primary"
            style="width: 100%"
          >
            Результаты выбора
          </el-button>
        </report-result-modal>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="showEditDialog=false">Отмена</el-button>
        <el-button
          size="small"
          type="primary"
          :loading="loading"
          @click="saveSavedReportForm"
        >
          Сохранить
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>


import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator.vue";
import UsersTable from "@/components/user/UsersTable.vue";
import Builder from "@/components/report/builder/Builder.vue";
import BuilderViewer from "@/components/report/builder/BuilderViewer.vue";
import ReportResultModal from "@/components/report/builder/ReportResultModal.vue";

export default {
  name: "entity-saved-report-form",
  components: {ReportResultModal, BuilderViewer, Builder, UsersTable},

  props: {
    entityType: {type: String, required: true},
    entityId: {type: Number|null, required: true},
    entityReportKey: {type: String, required: true},
    deleteOnEmptySearch: {type: Boolean, default: false},
  },
  computed: {},

  mounted() {
    this.loadSavedReportForm();
  },
  watch: {
    entityId: function () {
      this.loadSavedReportForm();
    },
  },
  data() {
    return {
      loading: false,
      reportForm: {},

      showEditDialog: false,
    }
  },
  methods: {
    loadSavedReportForm() {
      if( !this.entityId ){
        return;
      }
      this.loading = true;
      this.$api.report.getVisualQueryBuilderForm({
        entity_type: this.entityType,
        entity_id: this.entityId,
      })
        .then(data => {
          if (data.query_builder_form) {
            data = data.query_builder_form.data;
            this.reportForm = {
              builderElements: data.elements,
              builderFields: data.selected_fields,
              builderRelations: data.relations,
              builderSearch: data.search_fields,
            };
          } else {
            this.reportForm = {
              builderElements: [],
              builderFields: [],
              builderRelations: [],
              builderSearch: [],
            };
            this.setEntityBlock()
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },

    saveSavedReportForm(){
      this.loading = true;
      this.$api.report.saveVisualQueryBuilderForm({
        entity_type: this.entityType,
        entity_id: this.entityId,

        delete_on_empty_search: this.deleteOnEmptySearch,

        relations: this.reportForm.builderRelations,
        elements: this.reportForm.builderElements,
        selected_fields: this.reportForm.builderFields,
        search_fields: this.reportForm.builderSearch,
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Настройки доступа сохранены'
          });
        })
        .finally(() => {
          this.loading = false;
          this.showEditDialog = false;
        })
    },

    setEntityBlock() {
      this.loadRootBlocks(this.entityReportKey)
        .then((blocks) => {
          this.reportForm.builderElements = structuredClone(blocks);
        })

    },


    loadNodeRelations(node, resolve) {
      this.getBlockChildren(node.key)
        .then(blocks => {
          resolve(blocks);
        })
    },

    loadRootBlocks(forEntity) {
      return this.getBlockChildren()
        .then(blocks => {
          let filteredBlocks = [];
          blocks.forEach(block => {
            if (block.key === forEntity) {
              filteredBlocks.push(block);
              this.reportForm.builderFields = [{
                relationsChain: block.relationChain,
                block: block.key,
                field: 'id',
                name: 'ID'
              }];
            }
          });
          return filteredBlocks;
        })
    },

    getBlockChildren(parent_key) {
      return this.$api.report.getBlocksByParent({
        parent_key: parent_key
      })
        .then(data => {
          return data.blocks;
        })
    },


    nodeCheckChanged(node){
      if( !node.checked ){
        return;
      }

      if( node.relationChain.length === 1 ){
        this.builderFields = [{
          relationsChain: node.relationChain,
          block: node.key,
          field: 'id',
          name: 'ID'
        }];

        this.currentRootNode = node;
      }
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";

</style>