<template>
  <div v-if="$canAndRulesPass(user, $permissions.MATRIX.VIEW)">
    <page-heading
      heading="Матрица КПЭ"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>

    <div>
      <select-cards-modal
        @cards-selected="cardsSelected"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          icon="fas fa-list"
        >
          Выбрать карты
        </el-button>

      </select-cards-modal>
    </div>


    <div style="margin-top: 20px;" v-loading="loading">

      <component
        :is="matrixSummaryTemplate"
        :matrix="matrix"
        :scroll.sync="tablesScroll"
        @remove-card="removeCard"
      ></component>

      <matrix-section
        v-for="section in matrix.sections"
        :key="section.type"
        :section="section"
        :cards="matrix.cards"
        :scroll.sync="tablesScroll"
      ></matrix-section>
    </div>


  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import MatrixSection from "@/components/matrix/MatrixSection";
import SelectCardsModal from "@/components/matrix/SelectCardsModal";
import {mapGetters} from "vuex";

export default {
  name: 'Matrix',
  components: {SelectCardsModal, MatrixSection, PageHeading},

  computed: {
    ...mapGetters(['user']),
    matrixSummaryTemplate() {
      return this.$companyConfiguration.matrix.getSummaryTemplate();
    }
  },
  data() {
    return {
      loading: false,
      selectedCards: [],
      tablesScroll:0,

      matrix: {
        sections: {},
        cards: {},
      },
    }
  },
  mounted() {
    // this.loadMatrix();
  },
  methods: {

    cardsSelected(cards){
      this.selectedCards = [...this.selectedCards, ...cards];
      this.loadMatrix();
    },

    removeCard(cardId){
      this.selectedCards = this.selectedCards.filter(c => c.id !== cardId);
      this.loadMatrix();
    },

    loadMatrix() {
      this.loading = true;
      let cardIds = this.selectedCards.map( card => card.id );
      this.$api.matrix.view({
        // card_ids: [7,8],
        // card_ids: [1,2],
        card_ids: cardIds,
        expand: [
          'scale',
          'permissions',
          'fields_permissions',
          'user',
        ].join(),
      })
        .then((data) => {
          this.matrix = data.matrix;
        })
        .finally(() => {
          this.loading = false;
        })
    },




  }
}

</script>


<style scoped lang="scss">

</style>
