<template>

  <div>

    <h2 style="margin:0 0 10px 0">Шаблоны отчетов</h2>

    <el-table
      style="width: 100%"
      :data="reports"
      empty-text="Нет сохраненных отчетов"
      v-loading="loading"
      :show-header="false"
    >
      <el-table-column prop="name" label="Название отчета">
        <template slot-scope="scope">
          <span
            class="el-link"
            @click="reportClicked(scope.row)"
          >
              {{ scope.row.name }}
          </span>
        </template>
      </el-table-column>
      <!--            <el-table-column label="Доступ" width="210"  align="center">-->
      <!--                <template slot-scope="scope">-->
      <!--                    {{ $constants.savedReportAccessNames[scope.row.access] }}-->
      <!--                </template>-->
      <!--            </el-table-column>-->

      <el-table-column width="70" align="center">
        <template slot-scope="scope">
          <el-popconfirm
            confirm-button-text='Да'
            cancel-button-text='нет'
            icon="el-icon-question"
            title="Вы уверены, что хотите удалить отчет?"
            @confirm="deleteReport(scope.row)"
          >
            <el-button
              class="table-action-button"
              slot="reference"
              size="mini"
              round
              :loading="loading"
              icon="fas fa-trash-alt"
            >
            </el-button>
          </el-popconfirm>


        </template>
      </el-table-column>
    </el-table>

  </div>


</template>

<script>


export default {
  name: "SavedReports",
  components: {},
  props: {},
  computed: {},

  data() {
    return {
      loading: false,
      reports: [],
    }
  },
  mounted() {
    this.getReports()
  },
  methods: {
    getReports() {
      this.loading = true;
      this.$api.report.getSavedReports()
        .then((data) => {
          this.reports = data.reports;
        })
        .finally(() => {
          this.loading = false;
        })
    },

    reportClicked(row){
      this.loading = true;
      this.$api.report.getVisualQueryBuilderForm({
        entity_type: this.$constants.visualQueryBuilder.formEntityTypes.TYPE_REPORT,
        entity_id: row.id,
      })
        .then(data => {
          if (data.query_builder_form) {
            this.$emit('report-selected', {
              entity_type: this.$constants.visualQueryBuilder.formEntityTypes.TYPE_REPORT,
              entity_id: row.id,
              name: row.name,
              ...data.query_builder_form.data,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },

    deleteReport(report) {
      this.loading = true;
      this.$api.report.deleteReport({
        id: report.id
      })
        .then((data) => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Отчет успешно удален'
          });
        })
        .finally(() => {
          this.getReports();
        })
    }

  }
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";


</style>