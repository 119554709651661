import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {

        getPermissionsWithRoles(payload){
            return requestSender('get', 'rbac/get-permissions-with-roles', payload);
        },
        setRolePermission(payload){
            return requestSender('post', 'rbac/set-role-permission', payload);
        },
        getHistory(payload){
            return requestSender('get', 'rbac/get-history', payload);
        },
        assignUsersToRole(payload){
            return requestSender('post', 'rbac/assign-users-to-role', payload);
        },
        revokeUsersFromRole(payload){
            return requestSender('post', 'rbac/revoke-users-from-role', payload);
        },

        saveRole(payload){
            return requestSender('post', 'rbac/save-role', payload);
        },
        deleteRole(payload){
            return requestSender('post', 'rbac/delete-role', payload);
        },

    }
}