<template>
  <div class="login" v-loading="loadingLogin">
    <el-card style="padding: 24px">
      <h2>Авторизация</h2>


      <auth-method
        v-for="authMethodName in $companyConfiguration.auth.getEnabledAuthMethods()"
        :key="authMethodName"
        :method-name="authMethodName"
      ></auth-method>


    </el-card>
  </div>
</template>

<script>


import AuthMethod from "@/auth/AuthMethod.vue";
import {mapGetters} from "vuex";

export default {
  name: 'Login',
  components: {AuthMethod},

  computed: {
    ...mapGetters(['loadingLogin']),
  },

  data() {
    return {
    }
  },

  methods: {}
}

</script>

<style lang="scss" scoped>

.login {
  height: 90vh;
  position: relative;
  /*border: 3px solid green;*/
}

.el-card {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


</style>
<style lang="scss">

.login .el-card {
  //margin-top:300px;
  width: 340px;
  //display: flex;
  justify-content: center;
}
</style>
