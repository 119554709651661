<template>
  <el-row
    type="flex"
    justify="space-between"
  >
    <el-col :span="7">
      <el-form-item label="Год" prop="year">
        <el-date-picker
          v-model="yearLocal"
          style="width:100%"
          type="year"
          value-format="yyyy"
          @input="yearChanged"
        >
        </el-date-picker>
      </el-form-item>
    </el-col>
    <el-col
      v-show="!(Object.keys($companyConfiguration.card.getAvailablePeriods()).length === 1)"
      :span="7"
    >
      <el-form-item label="Период оценки" prop="period">
        <el-select
          v-model="periodLocal"
          style="width:100%"
          @change="periodChanged"
        >
          <el-option
            v-for="(cardPeriodName, cardPeriodLength) in $companyConfiguration.card.getAvailablePeriods()"
            :key="cardPeriodLength"
            :label="cardPeriodName"
            :value="+cardPeriodLength"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col
      :span="7"
    >
      <el-form-item
        v-show="!(availablePeriodIndexes === 1)"
        label="Номер периода"
        prop="period_index"
      >
        <el-select
          v-model="periodIndexLocal"
          no-data-text="Выберите период"
          style="width:100%"
          @change="periodIndexChanged"
        >
          <el-option
            v-for="index in availablePeriodIndexes"
            :key="index"
            :label="index"
            :value="index"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script>

export default {
  name: "period-selector",
  props: {
    year: {},
    period: {type: Number},
    periodIndex: {type: Number},
  },

  computed: {
    availablePeriodIndexes() {
      if (!this.periodLocal) {
        return 0;
      }
      return 12 / this.periodLocal;
    },
  },
  data() {
    return {
      yearLocal: '',
      periodLocal: null,
      periodIndexLocal: null,
    }
  },
  mounted() {
    this.yearLocal = this.year ? this.year.toString() : '';
    this.periodLocal = +this.period;
    this.periodIndexLocal = +this.periodIndex;

    if( !this.periodLocal ){
      this.periodLocal = Object.keys(this.$companyConfiguration.card.getAvailablePeriods()).length === 1
        ? +Object.keys(this.$companyConfiguration.card.getAvailablePeriods())[0]
        : null;
      this.periodChanged();
    }

    this.checkPeriodIndexAlone();
  },
  methods: {
    yearChanged(){
      this.$emit('update:year', this.yearLocal);
    },
    periodChanged(){
      this.$emit('update:period', this.periodLocal);

      this.periodIndexLocal = null;
      this.periodIndexChanged();

      this.checkPeriodIndexAlone();
    },
    periodIndexChanged(){
      this.$emit('update:periodIndex', this.periodIndexLocal);
    },
    checkPeriodIndexAlone(){
      if( !this.periodIndexLocal && this.availablePeriodIndexes === 1){
        this.periodIndexLocal = 1;
        this.periodIndexChanged();
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";

</style>
