<template>


  <div class="comments-block">
    <div class="comments-block-messages" ref="comments-block-messages" :style="{'max-height': height}">
      <div
        v-if="!!comments.length"
        class="comments-block-messages-message"
        v-for="comment in comments"
        :key="comment.id"
      >
        <div class="comments-block-messages-message-title">
          <div>{{ comment.author.full_name }}</div>
          <div>
            {{ new Date(comment.created_at).toLocaleString($companyConfiguration.helpers.getDateLocale()) }}
            <el-popconfirm
              v-if="$canAndRulesPass(comment, $permissions.COMMENT.DELETE)"
              style="margin-left: 20px"
              confirm-button-text='Да'
              cancel-button-text='нет'
              icon="el-icon-question"
              title="Вы уверены, что хотите удалить сообщение?"
              @confirm="deleteComment(comment)"
            >
              <el-button
                slot="reference"
                class="table-action-button"
                type="default"
                round
                size="mini"
                icon="fas fa-trash"
                :loading="loading || loadingFileUpload"
              >
              </el-button>

            </el-popconfirm>
          </div>
        </div>
        <div class="comments-block-messages-message-body">
          {{ comment.text }}
          <div
            v-for="attachment in comment.attachments"
            :key="attachment.id"
          >
            {{ attachment.name }}
            (<a @click.prevent="downloadAttachment(attachment)">Скачать</a>)
          </div>
        </div>
      </div>
      <div v-if="!comments.length" align="center">
        Нет комментариев
      </div>
    </div>

    <el-row type="flex" v-if="!disabled">
      <div style="width:100%; margin-right:20px">
        <el-input
          v-model="messageText"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 6}"
          placeholder="Введите сообщение"
          size="mini"
          clearable
        >
        </el-input>
        <el-button
          :loading="loading"
          :disabled="!messageText && (!$refs['file-uploader'] || !$refs['file-uploader'].uploadFiles.length)"
          size="mini"
          type="primary"
          style="width:100%; margin-top:5px"
          @click="saveComment"
        >
          {{ sendButtonText }}
        </el-button>
      </div>
      <el-upload
        ref="file-uploader"
        class="chat-dragger"
        drag
        action=""
        multiple
        :auto-upload="false"
        :http-request="doFileUpload"
        :on-exceed="onExceed"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Перетащите или нажмите для загрузки файла</div>
      </el-upload>
    </el-row>
  </div>


</template>

<script>

import {paginator} from "@/mixins/paginator";
import {downloader} from "@/mixins/downloader";

export default {
  name: 'comments-block',
  components: {},
  mixins: [paginator, downloader],

  props: {
    entityType: {type: String, required: true},
    entityId: {type: Number, required: true},
    entitySegment: {type: String, default: null},
    sendButtonText: {type: String, default: 'Отправить'},

    disabled: {type: Boolean, default: false},

    height: {
      type: String,
      default: '50vh'
    },
  },
  mounted() {
    this.getComments();
  },
  data() {
    return {
      loading: false,
      loadingFileUpload: false,
      comments: [],

      messageText: null,
      lastCreatedCommentId: null,
    }
  },
  watch: {},
  computed: {},
  methods: {
    scrollToBottom(){
      this.$nextTick(function () {
        if( this.$refs['comments-block-messages'] ){
          this.$refs['comments-block-messages'].scrollTop = this.$refs['comments-block-messages'].scrollHeight;
        }
      })
    },

    getComments() {
      this.loading = true;
      this.$api.comment.search({
        entity_type: this.entityType,
        entity_id: this.entityId,
        entity_segment: this.entitySegment,
        'expand': 'author, attachments, permissions',
      })
        .then(data => {
          this.comments = data.comments;
          this.scrollToBottom();
        })
        .finally(() => {
          this.loading = false;
        })
    },

    saveComment() {
      if (!this.messageText && !this.$refs["file-uploader"].uploadFiles.length) {
        return;
      }

      this.loading = true;
      this.$api.comment.create({
        entity_type: this.entityType,
        entity_id: this.entityId,
        entity_segment: this.entitySegment,
        text: this.messageText,
      })
        .then(data => {
          this.comments.push(data.comment);
          this.scrollToBottom();
          this.messageText = null;
          this.lastCreatedCommentId = data.comment.id;
          this.$refs["file-uploader"].submit();
          this.$emit('comment-added', data.comment);
        })
        .finally(() => {
          this.loading = false;
        })
    },


    deleteComment(comment) {
      this.$api.comment.delete({
        id: comment.id,
      })
        .then(data => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Комментарий успешно удален'
          });
          this.getComments();
        })
        .finally(() => {
        })
    },

    doFileUpload(params) {
      this.loadingFileUpload = true;
      let form = new FormData();
      form.append('comment_id', this.lastCreatedCommentId);
      form.append('attachment', params.file);
      return this.$api.comment.attach(form)
        .then(data => {
          this.$refs["file-uploader"].clearFiles();
          this.getComments()
        })
        .finally(()=>{
          this.loadingFileUpload = false;
        })
    },
    onExceed(params) {
      this.$notify.error({
        title: 'Лимит',
        message: 'Не более одного элемента'
      });
    },


    downloadAttachment(attachment) {
      this.downloadFile(
        false,
        this.$api.comment.downloadAttachment,
        { id: attachment.id }
      );
    }
  }
}

</script>

<style scoped lang="scss">
@import "./src/assets/css/project-variables";
a {
  color: $--color-primary;
}

a:hover {
  cursor: pointer;
}

.comments-block {


  &-messages {
    max-height: 50vh;
    overflow: scroll;
    overflow-x: unset;
    overflow-y: auto;
    margin-bottom: 20px;

    &-message {
      padding: 10px 20px;
      /*background-color: #f1f1f1;*/
      background-color: #F5F7FA;

      &-title {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
      }

      &-body {
        white-space: pre-wrap;
      }
    }


  }
}


.comments-block .comments-block-messages .comments-block-message:not(:last-child) {
  margin-bottom: 20px;
}


</style>
<style>
.chat-dragger .el-upload-dragger {
  height: 80px;
  width: 200px;
  padding: 5px 10px;
  border-radius: unset;
}

.el-upload-dragger .el-icon-upload {
  font-size: 26px;
  margin: 0;
  line-height: unset;
}
</style>