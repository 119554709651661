<template>
  <div v-if="$canAndRulesPass(user, $permissions.REPORT.USE)">

    <page-heading
      heading="Конструктор отчетов"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>


    <saved-reports
      :key="reloadSavedReportsTable"
      style="margin-bottom:30px"
      @report-selected="reportSelected"
    ></saved-reports>


    <builder
      :loading="loading"
      :elements="builderElements"
      :selected-fields.sync="builderFields"
      :relations.sync="builderRelations"
      :search-fields.sync="builderSearch"
      :loaded-report="loadedReport"
      :load-children="loadNodeRelations"

      :save-report-actions="true"

      @build-report="buildReport"
      @export-report="buildReport($event, true)"
      @report-saved="reportSaved"
    ></builder>


    <report-result
      ref="reportResultTable"
      :builder-fields="builderFields"
      :builder-relations="builderRelations"
      :builder-search="builderSearch"
    ></report-result>

  </div>

</template>

<script>

import {mapGetters} from "vuex";
import Builder from "../components/report/builder/Builder";
import Paginator from "../components/Paginator";
import PageHeading from "../components/PageHeading";
import SavedReports from "../components/report/SavedReports";
import {downloader} from "@/mixins/downloader";
import ReportResult from "@/components/report/builder/ReportResult.vue";

export default {
  name: 'Report',
  mixins: [downloader],
  components: {ReportResult, SavedReports, PageHeading, Paginator, Builder},

  data() {
    return {

      loading: false,

      reloadSavedReportsTable: 0,

      builderElements: [],
      builderFields: [],
      builderRelations: [],
      builderSearch: [],

      loadedReport: {
        id: null,
        name: null,
      },
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  mounted() {
    this.loadRootBlocks();
  },
  methods: {
    loadNodeRelations(node, resolve) {
      this.getBlockChildren(node.key)
        .then(blocks => {
          resolve(blocks);
        })
    },

    loadRootBlocks() {
      this.getBlockChildren()
        .then(blocks => {
          this.builderElements = blocks;
        })
    },


    getBlockChildren(parent_key) {
      return this.$api.report.getBlocksByParent({
        parent_key: parent_key
      })
        .then(data => {
          return data.blocks;
        })
    },


    buildReport({selectedFields, relations, searchFields}, toExcel = false) {
      this.builderFields = [];
      this.builderFields = selectedFields;
      this.builderRelations = relations;
      this.builderSearch = searchFields;

      if (!toExcel) {
        this.$refs.reportResultTable.loadReportData(1);
      } else {
        this.exportReportData();
      }
    },

    exportReportData() {

      this.loading = true;
      this.downloadFile(
        'export.xlsx',
        this.$api.report.exportReportToXls,
        {
          selected_fields: this.builderFields,
          relations: this.builderRelations,
          search_fields: this.builderSearch
        }
      )
        .finally(() => {
          this.loading = false;
        });
    },


    reportSelected(report) {
      this.loadedReport = report;
      this.builderElements = report.elements;
      this.builderRelations = report.relations;
      this.builderFields = report.selected_fields;
      this.builderSearch = report.search_fields;
    },

    reportSaved(report) {
      this.reloadSavedReportsTable = !this.reloadSavedReportsTable;
      this.loadedReport = report
    },
  }
}

</script>


<style>
.el-cascader-menu__wrap {
    height: unset;
}

.report-cascader li.el-cascader-node {
    height: unset;
    align-items: baseline;
}

.report-cascader li.el-cascader-node i.el-cascader-node__postfix {
    top: 10px;
}


</style>
