export default {
    "summary_template": "componentsCompany/evraz/matrix/MatrixSummary",
    "sections_columns_config": {
        "multiplier": "multiplier_columns",
        "multiplier_manual_fact": "multiplier_columns"
    },
    "default_columns": [
        {
            "label": "Наименование показателя",
            "width": 350,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "Вес",
            "width": 90,
            "manualColumnTemplate": true,
            "template": "components/tableColumnTemplates/matrix/MatrixWeightTableColumn",
            "bind": {
                "cards": "templateData.cards",
                "cardsWeights": "templateData.cardsWeights",
                "scope": "templateData"
            }
        },
        {
            "label": "Единица измерения",
            "width": 90,
            "template": "components/tableColumnTemplates/card/ElementLibraryUnitTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "Целевые значения",
            "width": 600,
            "template": "components/tableColumnTemplates/card/ElementScaleConfigTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "Факт",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementFactTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/matrix/MatrixActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "align": "'center'",
            "width": 50
        }
    ],
    "multiplier_columns": [
        {
            "label": "Наименование коэффициенты",
            "width": 350,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "Наличие",
            "width": 90,
            "manualColumnTemplate": true,
            "template": "components/tableColumnTemplates/matrix/MatrixMultiplierSectionWeightTableColumn",
            "bind": {
                "cards": "templateData.cards",
                "cardsWeights": "templateData.cardsWeights",
                "scope": "templateData"
            }
        },
        {
            "label": "Целевые значения",
            "width": 690,
            "template": "components/tableColumnTemplates/card/ElementScaleConfigTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "Факт",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementFactTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/matrix/MatrixActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "align": "'center'",
            "width": 50
        }
    ]
}