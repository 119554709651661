export default {
    "available_periods": {
        "1": "Месяц",
        "3": "Квартал"
    },
    "available_sections_for_period": {
        "1": {
            "basic": {
                "selected": false
            },
            "basic_manual_fact": {
                "selected": false
            },
            "other": {
                "selected": false
            },
            "multiplier": {
                "selected": false
            },
            "multiplier_manual_fact": {
                "selected": false
            }
        },
        "3": {
            "basic": {
                "selected": false
            },
            "basic_manual_fact": {
                "selected": false
            },
            "other": {
                "selected": false
            },
            "multiplier": {
                "selected": false
            },
            "multiplier_manual_fact": {
                "selected": false
            }
        }
    },
    "cards_create_info_template": null,
    "medium_facts": {
        "available": false
    },
    "cards_visible_permissions_criteria_map": [],
    "cards_page_filters": {
        "template": "componentsCompany/evraz/CardsPageFilters",
        "search_fields": {
            "card_info.last_name": {
                "relation": "info"
            },
            "card_info.middle_name": {
                "relation": "info"
            },
            "card_info.first_name": {
                "relation": "info"
            },
            "card_info.position": {
                "relation": "info"
            }
        },
        "default_sort_params": {
            "attributes": {
                "0": "year",
                "info.full_name": {
                    "asc": {
                        "card_info.last_name": 4
                    },
                    "desc": {
                        "card_info.last_name": 3
                    }
                }
            },
            "defaultOrder": {
                "year": 3,
                "info.full_name": 4
            }
        }
    },
    "cards_page_columns": [
        {
            "label": "ID",
            "prop": "id",
            "width": 60
        },
        {
            "label": "Год",
            "prop": "year",
            "width": 80
        },
        {
            "label": "ФИО",
            "template": "components/tableColumnTemplates/cards/RouterLinkToCardColumnTemplate",
            "prop": "info.full_name"
        },
        {
            "label": "Должность",
            "prop": "info.position"
        },
        {
            "label": "Период",
            "template": "componentsCompany/evraz/tableColumnTemplates/cards/PeriodColumnTemplate"
        },
        {
            "label": "Этап",
            "template": "components/tableColumnTemplates/cards/CurrentStageColumnTemplate",
            "prop": "currentStage.type"
        },
        {
            "width": 100,
            "label": "Процент премии",
            "prop": "bonus|numberFormat"
        },
        {
            "template": "components/tableColumnTemplates/cards/ActionsColumnTemplate",
            "width": 80,
            "align": "right",
            "on": {
                "delete-card": "(e) => { templateData.$emit(\"delete-card\", e) }"
            }
        }
    ],
    "card_header_elements": [
        {
            "label": "Сотрудник",
            "icon": "fas fa-id-card-alt",
            "prop": "user.full_name"
        },
        {
            "label": "Должность",
            "icon": "fas fa-briefcase",
            "prop": "user.position"
        },
        {
            "label": "Административный руководитель",
            "icon": "far fa-user",
            "prop": "user.chiefs.direct.full_name"
        },
        {
            "label": "Функциональный руководитель",
            "icon": "fas fa-user",
            "prop": "user.chiefs.functional.full_name"
        }
    ],
    "card_selectable_page_columns": []
}