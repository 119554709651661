import requestSender from "./base/requestSender";

export default function (axiosInstance) {
    return {
        search(payload) {
            return requestSender('get', 'comment/search', payload);
        },
        create(payload) {
            return requestSender('post', 'comment/create', payload);
        },
        delete(payload) {
            return requestSender('post', 'comment/delete', payload);
        },

        attach(payload) {
            return requestSender('post', 'comment/attach', payload);
        },
        downloadAttachment(payload, options){
            return requestSender('post', 'comment/download-attachment', payload, options);
        },

    }
}