export default {
    "sectionsBlockHeaderTemplate": "componentsCompany/evraz/CardSectionsBlockHeader",
    "sectionsBlockFooterTemplate": "componentsCompany/evraz/CardSectionsBlockFooter",
    "sections": {
        "basic": {
            "showName": "KPI",
            "emptyText": "Нет целей",
            "fromLibrary": "basic",
            "fieldsAvailableForEdit": {
                "weight": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ]
                }
            }
        },
        "basic_manual_fact": {
            "showName": "KPI с ручным фактом",
            "emptyText": "Нет целей",
            "fromLibrary": "basic_manual_fact",
            "fieldsAvailableForEdit": {
                "weight": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.fact": {
                    "stages": [
                        "assessment"
                    ]
                },
                "comment.plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ]
                }
            }
        },
        "other": {
            "showName": "Прочее",
            "emptyText": "Нет целей",
            "fieldsAvailableForEdit": {
                "name": {
                    "stages": [
                        "plan"
                    ],
                    "permissions": [
                        "card_element.update_in_other_section"
                    ]
                },
                "desc": {
                    "stages": [
                        "plan"
                    ],
                    "permissions": [
                        "card_element.update_in_other_section"
                    ]
                },
                "weight": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.unit_id": {
                    "stages": [
                        "plan"
                    ],
                    "permissions": [
                        "card_element.update_in_other_section"
                    ]
                },
                "scale": {
                    "stages": [
                        "plan"
                    ],
                    "permissions": [
                        "card_element.update_in_other_section"
                    ]
                },
                "scale.fact": {
                    "stages": [
                        "assessment"
                    ],
                    "permissions": [
                        "card_element.update_in_other_section"
                    ]
                },
                "comment.plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ]
                }
            },
            "createElementBusinessRulePermissions": [
                "card_element.create_in_other_section"
            ]
        },
        "multiplier": {
            "showName": "Коэффициенты",
            "emptyText": "Нет коэффициентов",
            "multiplyElementsBonus": true,
            "fromLibrary": "multiplier",
            "columnsConfig": "multiplier_columns",
            "hideOnStagesIfEmpty": [
                "assessment",
                ""
            ],
            "fieldsAvailableForEdit": {
                "weight": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ]
                }
            }
        },
        "multiplier_manual_fact": {
            "showName": "Коэффициенты с ручным фактом",
            "emptyText": "Нет коэффициентов",
            "multiplyElementsBonus": true,
            "fromLibrary": "multiplier_manual_fact",
            "hideOnStagesIfEmpty": [
                "assessment",
                ""
            ],
            "fieldsAvailableForEdit": {
                "weight": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.fact": {
                    "stages": [
                        "assessment"
                    ]
                },
                "comment.plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ]
                }
            }
        }
    },
    "default_columns": [
        {
            "label": "Наименование показателя",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope"
            }
        },
        {
            "label": "Вес",
            "width": 90,
            "template": "components/tableColumnTemplates/card/ElementWeightTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Единица измерения",
            "width": 120,
            "template": "components/tableColumnTemplates/card/ElementLibraryUnitTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Целевые значения",
            "width": 600,
            "template": "components/tableColumnTemplates/card/ElementScaleConfigTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий к цели",
            "if": "templateData.$canAndRulesPass(templateData.card, templateData.$permissions.COMMENT.SEE)",
            "width": 110,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'plan'",
                "last-comment": "scope.row.last_comment_by_stage['plan']",
                "comments-count": "scope.row.comments_count_by_stage['plan']"
            }
        },
        {
            "label": "Факт",
            "width": 120,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/tableColumnTemplates/card/ElementFactTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Комментарий к выполнению",
            "if": "templateData.$canAndRulesPass(templateData.card, templateData.$permissions.COMMENT.SEE)",
            "width": 120,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment'",
                "last-comment": "scope.row.last_comment_by_stage['assessment']",
                "comments-count": "scope.row.comments_count_by_stage['assessment']"
            }
        },
        {
            "label": "Процент премии",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "prop": "bonus|numberFormat",
            "width": 100
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ],
    "multiplier_columns": [
        {
            "label": "Наименование коэффициента",
            "minWidth": 200,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope"
            }
        },
        {
            "label": "Целевые значения",
            "width": 600,
            "template": "components/tableColumnTemplates/card/ElementScaleConfigTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий к цели",
            "if": "templateData.$canAndRulesPass(templateData.card, templateData.$permissions.COMMENT.SEE)",
            "width": 110,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'plan'",
                "last-comment": "scope.row.last_comment_by_stage['plan']",
                "comments-count": "scope.row.comments_count_by_stage['plan']"
            }
        },
        {
            "label": "Факт",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/tableColumnTemplates/card/ElementFactTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Комментарий к выполнению",
            "if": "templateData.$canAndRulesPass(templateData.card, templateData.$permissions.COMMENT.SEE)",
            "width": 120,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment'",
                "last-comment": "scope.row.last_comment_by_stage['assessment']",
                "comments-count": "scope.row.comments_count_by_stage['assessment']"
            }
        },
        {
            "label": "Значение коэффициента",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "prop": "bonus",
            "width": 100
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope",
                "is-field-available-for-edit": "templateData.isFieldAvailableForEdit",
                "save-element-prop-change": "templateData.saveElementPropChange"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ]
}