<template>
  <div
    class="card-section"
  >
    <div class="card-section-header">
      <div class="card-section-header-name">
        {{ $companyConfiguration.cardSections.getSectionConfig(section.type).showName }}
      </div>
    </div>


    <el-table
      v-loading="loading"
      class="card-section-elements-table"
      :show-header="!!elements.length"
      style="width: 100%"
      border
      size="mini"
      :data="elements"
      :empty-text="$companyConfiguration.cardSections.getSectionConfig(section.type).emptyText"
      @selection-change="$emit('selection-changed', $event)"
    >
      <el-table-column type="selection" width="55" ></el-table-column>

      <component
        v-for="(columnConfig, index) in columnsConfigs"
        :key="index"
        v-if="columnConfig.getVif(thisComponent)"
        :is="columnConfig.manualColumnTemplate ? columnConfig.template : baseTableColumn"
        :column-config="columnConfig"
      >
      </component>
    </el-table>


  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit";
import CardElementScaleEditDialog from "@/components/cards/CardElementsBlock/CardElementScaleEditDialog";
import ElementAddFromLibraryDialog from "@/components/cards/CardElementsBlock/ElementAddFromLibraryDialog";
import ElementNameTableColumn from "@/components/tableColumnTemplates/card/ElementNameTableColumn";
import {mapGetters} from "vuex";

export default {
  name: "card-section-selectable",
  components: {
    ElementNameTableColumn, ElementAddFromLibraryDialog, CardElementScaleEditDialog, ClickToEdit
  },

  props: {
    section: {
      type: Object,
      required: true,
    },
  },

  computed: {
    baseTableColumn() {
      return () => import(`@/components/tableColumnTemplates/BaseTableColumn`);
    },
  },
  data() {
    return {
      thisComponent: this,
      loading: false,
      elements: [],

      columnsConfigs: [],
    }
  },
  beforeMount() {
    this.columnsConfigs = this.$companyConfiguration.helpers.getTableColumnsArrayFromConfig(
      this.$companyConfiguration.card.getCardSelectablePageColumns()
    );
  },
  mounted() {
    this.loadElements();
  },
  methods: {
    loadElements() {
      this.loading = true;
      this.$api.card.getCardSectionElements({
        section_id: this.section.id,
        expand: [
          'scale',
        ].join(),
      })
        .then((data) => {
          this.elements = data.elements;
        })
        .finally(() => {
          this.loading = false;
        })
    },
  }
}
</script>


<style scoped lang="scss">
.card-section {
  margin-bottom: 30px;

  &-header {
    font-weight: bold;
    margin-bottom: 5px;

    &-name {
      display: inline-block;
      min-width: 100px;
    }
  }
}

</style>