import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {

        import(payload){
            return requestSender('post', 'export-import/import', payload);
        },
        getImportExample(payload, options) {
            return requestSender('post', 'export-import/get-import-example', payload, options);
        },

        export(payload){
            return requestSender('post', 'export-import/export', payload);
        },


    }
}