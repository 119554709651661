<template>

  <div class="selected-field-item">
    <div class="selected-field-item-relation">{{ searchField.relationName }}</div>
    <div class="selected-field-item-name">{{ searchField.name }}</div>
    <div class="selected-field-item-name">
      <el-select
        :disabled="disabled"
        v-model="searchField.condition"
        placeholder="Выберите"
      >
        <el-option value="=" label="Равно"></el-option>
        <el-option value="<>" label="Не равно"></el-option>
        <el-option value=">=" label="Больше или равно"></el-option>
        <el-option value="<=" label="Меньше или равно"></el-option>
        <el-option value="like" label="Содержит"></el-option>
        <el-option value="not like" label="Не содержит"></el-option>
        <el-option value="is_not_null" label="Не пусто"></el-option>
        <el-option value="is_null" label="Не задано"></el-option>
      </el-select>
    </div>
    <div v-if="searchField.condition !== 'is_null' && searchField.condition !== 'is_not_null'">
      <div class="selected-field-item-name" v-if="searchField.enum">
        <el-select
          :disabled="disabled"
          v-model="searchField.search"
          placeholder="Выберите"
        >
          <el-option
            v-for="(optionName, optionValue) in searchField.enum"
            :key="optionValue"
            :label="optionName"
            :value="optionValue">
          </el-option>
        </el-select>
      </div>
      <div class="selected-field-item-name" v-else>
        <el-input
          :disabled="disabled"
          v-model="searchField.search"
        ></el-input>
      </div>
    </div>

  </div>


</template>

<script>

export default {
  name: "BuilderSelectedFieldItem",
  components: {},
  props: {
    searchField: Object,
    disabled: {type: Boolean, default: false}
  },
  computed: {},

  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";


.selected-field-item {
  cursor: move;
  text-align: center;
  border: 1px solid $--color-primary;
  margin: 5px;
  background-color: white;
}

.selected-field-item-relation {
  padding: 10px;
  background-color: $--color-primary;
}

.selected-field-item-name {
  padding: 10px;
}

</style>