<template>
  <el-select
    v-model="localValue"
    :size="size"
    :multiple="multiple"
    remote
    clearable
    reserve-keyword
    placeholder="Группа"
    :remote-method="remoteMethod"

    @visible-change="$event ? remoteMethod() : null"
  >
    <div
      v-loading="loading"
      v-infinite-scroll="nextPage"
      :infinite-scroll-immediate="false"
      :infinite-scroll-delay="100"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </div>
  </el-select>

</template>

<script>


import {paginator} from "@/mixins/paginator";

export default {
  name: "user-group-select",
  mixins: [paginator],
  components: {},
  props: {
    value: {},
    size: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      loading: false,
      query: null,
      page: 1,
      options: [],
    }
  },
  mounted() {
    this.remoteMethod()
  },
  watch: {},
  computed: {
    localValue: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.selected(newValue)
      }
    }
  },
  methods: {
    remoteMethod(query) {
      this.query = query;
      this.page = 1;
      this.options = [];
      this.getData();
    },

    nextPage(){
      this.page++;
      this.getData();
    },

    getData() {
      if( this.paginator.pageCount && this.page > this.paginator.pageCount ){
        return;
      }
      this.loading = true;
      this.$api.group.search({
        // search: this.query,
        page: this.page
      })
        .then(({data, paginator}) => {
          this.options = this.options.concat(data.groups);
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    selected(value) {
      this.$emit('input', value);
      this.$emit('selected', value)
    }
  },

}
</script>
