export default {
    "chief_types": {
        "direct": {
            "showName": "Непосредственный руководитель"
        },
        "functional": {
            "showName": "Функциональный руководитель"
        }
    },
    "my_employees_types": {
        "direct": [],
        "functional": []
    },
    "users_visible_permissions_criteria_map": {
        "user.see_self": "app\\domain\\User\\criteria\\returnInNewQuery\\UserSelfCriteria",
        "user.see_subordinates": "app\\domain\\User\\criteria\\returnInNewQuery\\UserAllSubordinatesCriteria",
        "user.see_chiefs": "app\\domain\\User\\criteria\\returnInNewQuery\\UserChiefsCriteria"
    },
    "users_list_columns": [
        {
            "label": "ФИО",
            "prop": "full_name"
        },
        {
            "label": "Должность",
            "prop": "position"
        },
        {
            "label": "Подразделение",
            "prop": "company_division"
        },
        {
            "label": "Предприятие",
            "prop": "company"
        },
        {
            "label": "Email",
            "prop": "email"
        }
    ],
    "users_list_default_role_filter_in_search": "user",
    "sort_params": {
        "attributes": {
            "0": "id",
            "1": "company",
            "full_name": {
                "asc": {
                    "last_name": 4
                },
                "desc": {
                    "last_name": 3
                }
            }
        },
        "defaultOrder": {
            "full_name": 4
        }
    },
    "chiefs_list_columns": [
        {
            "label": "ФИО",
            "prop": "user.full_name"
        },
        {
            "label": "Должность",
            "prop": "user.position"
        },
        {
            "label": "Подразделение",
            "prop": "user.company_division"
        },
        {
            "label": "Предприятие",
            "prop": "user.company"
        },
        {
            "label": "Email",
            "prop": "user.email"
        },
        {
            "label": "Руководитель/администратор",
            "prop": "chief.full_name"
        }
    ],
    "chiefs_search_fields": {
        "users.last_name": {
            "relation": "user"
        },
        "users.first_name": {
            "relation": "user"
        },
        "users.middle_name": {
            "relation": "user"
        },
        "users.position": {
            "relation": "user"
        },
        "users.company_division": {
            "relation": "user"
        },
        "users.company": {
            "relation": "user"
        },
        "chiefs.last_name": {
            "relation": "chief chiefs"
        },
        "chiefs.first_name": {
            "relation": "chief chiefs"
        },
        "chiefs.middle_name": {
            "relation": "chief chiefs"
        }
    },
    "chiefs_sort_params": {
        "attributes": {
            "user.company": {
                "asc": {
                    "users.company": 4
                },
                "desc": {
                    "users.company": 3
                }
            },
            "user.full_name": {
                "asc": {
                    "users.last_name": 4
                },
                "desc": {
                    "users.last_name": 3
                }
            }
        },
        "defaultOrder": {
            "user.full_name": 4
        }
    }
}