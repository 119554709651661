import Api from "@/api/base";
import Vue from "vue";


export default {
    namespaced: true,

    state: {
        loading: false,
        sections: [],
        sectionsElements: {}, // {sectionId: [elements],}
        sectionsLoading: {}, // {sectionId: Boolean,}
    },
    getters: {
        loading(state) {
            return state.loading
        },
        sections(state) {
            return state.sections;
        },

        sectionsElements(state) {
            return state.sectionsElements;
        },
        sectionElements: (state) => (sectionId) => {
            if( !state.sectionsElements[sectionId] ){
                return [];
            }
            return state.sectionsElements[sectionId]
        },
        sectionLoading: (state) => (sectionId) => {
            if( !state.sectionsLoading[sectionId] ){
                return false;
            }
            return state.sectionsLoading[sectionId]
        }

    },
    actions: {
        loadSections(ctx, payload) {
            const card_id = payload?.id ? payload.id : ctx.rootState.card.card.id;
            if( !card_id ){
                return;
            }
            ctx.commit('setLoading', true);
            ctx.commit('updateSections', []);
            Api.card.getCardSections({
                id: card_id,
                expand: 'permissions'
            })
                .then(data => {
                    ctx.commit('updateSections', data.sections);
                })
                .finally(() => {
                    ctx.commit('setLoading', false);
                })

        },

        loadSectionElements(ctx, payload) {
            ctx.commit('setSectionLoading', {sectionId:payload.section_id, isLoading:true});
            Api.card.getCardSectionElements(payload)
                .then((data) => {
                    ctx.commit('updateSectionElements', {
                        sectionId: payload.section_id,
                        elements: data.elements
                    });
                })
                .finally(() => {
                    ctx.commit('setSectionLoading', {sectionId:payload.section_id, isLoading:false});
                })
        },

        unloadSections(ctx){
            ctx.commit('updateSections', []);
        },


    },

    mutations: {
        setLoading(state, isLoading) {
            state.loading = isLoading;
        },
        setSectionLoading(state, {sectionId, isLoading}) {
            Vue.set(state.sectionsLoading, sectionId, isLoading)
        },

        updateSections(state, sections) {
            state.sectionsLoading = {};
            state.sectionsElements = {};
            state.sections = sections;
        },

        updateSectionElements(state, {sectionId, elements}) {
            Vue.set(state.sectionsElements, sectionId, elements)
        },

    }
}