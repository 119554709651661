import Api from "@/api/base";
import Vue from "vue";

export default {
    namespaced: true,

    state: {
        loading: false,
        settingsOld: {
            scales: {
                fixedPayTargetA: 100,
                fixedPayTargetC: 101,
                fixedPayPayA: 102,
                fixedPayPayB: 103,
                fixedPayPayC: 104,
            }
        },
        settings: {},
    },

    getters: {
        loading(state) {
            return state.loading
        },
        settings(state) {
            return state.settings
        },
    },
    actions: {
        loadSettings(ctx, payload) {
            ctx.commit('setLoading', true);
            Api.settings.get()
                .then(data => {
                    ctx.commit('updateSettings', data.settings);
                })
                .finally(() => {
                    ctx.commit('setLoading', false);
                })
        },
        saveSetting(ctx, payload) {
            ctx.commit('setLoading', true);
            return Api.settings.save({
                name: payload.name,
                value: payload.value,
            })
                .then(data => {
                    ctx.commit('setSetting', {name: payload.name, value: payload.value});
                })
                .finally(() => {
                    ctx.commit('setLoading', false);
                })
        },
    },

    mutations: {
        setLoading(state, isLoading) {
            state.loading = isLoading;
        },
        updateSettings(state, settings) {
            settings.forEach(setting => {
                Vue.set(state.settings, setting.name, setting.value);
            })
        },
        setSetting(state, {name, value}) {
            Vue.set(state.settings, name, value);
        },
    },

    modules: {}
}