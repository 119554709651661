export default {
    "scales": {
        "scale_text": {
            "showName": "Текст",
            "templatesFolder": "componentsCompany/evraz/elementScales/text"
        },
        "scale_date": {
            "showName": "Дата",
            "templatesFolder": "componentsCompany/evraz/elementScales/date"
        },
        "scale_discrete": {
            "showName": "Дискретная",
            "templatesFolder": "componentsCompany/evraz/elementScales/discrete"
        },
        "scale_multiple_3_percent": {
            "showName": "Число 3 параметра процент",
            "templatesFolder": "componentsCompany/evraz/elementScales/numberMultiple",
            "varsAmount": 3,
            "viewSpan": 8,
            "colsConfig": {
                "col1": {
                    "name": "Min, %",
                    "percentFrom": "col2"
                },
                "col2": {
                    "name": "Цель"
                },
                "col3": {
                    "name": "Max, %",
                    "percentFrom": "col2"
                }
            }
        },
        "scale_multiple_4_percent": {
            "showName": "Число 4 параметра процент",
            "templatesFolder": "componentsCompany/evraz/elementScales/numberMultiple",
            "varsAmount": 4,
            "viewSpan": 8,
            "colsConfig": {
                "col1": {
                    "name": "Min, %",
                    "percentFrom": "col2"
                },
                "col2": {
                    "name": "Цель 1"
                },
                "col3": {
                    "name": "Цель 2",
                    "percentFrom": "col2"
                },
                "col4": {
                    "name": "Max, %",
                    "percentFrom": "col2"
                }
            }
        },
        "scale_multiple_3": {
            "showName": "Число 3 параметра",
            "templatesFolder": "componentsCompany/evraz/elementScales/numberMultiple",
            "varsAmount": 3,
            "viewSpan": 8
        },
        "scale_multiple_4": {
            "showName": "Число 4 параметра",
            "templatesFolder": "componentsCompany/evraz/elementScales/numberMultiple",
            "varsAmount": 4,
            "viewSpan": 8
        },
        "scale_multiple_5": {
            "showName": "Число 5 параметров",
            "templatesFolder": "componentsCompany/evraz/elementScales/numberMultiple",
            "varsAmount": 5,
            "viewSpan": 8
        },
        "scale_multiple_6": {
            "showName": "Число 6 параметров",
            "templatesFolder": "componentsCompany/evraz/elementScales/numberMultiple",
            "varsAmount": 6,
            "viewSpan": 8
        },
        "scale_multiple_8": {
            "showName": "Число 8 параметров",
            "templatesFolder": "componentsCompany/evraz/elementScales/numberMultiple",
            "varsAmount": 8,
            "viewSpan": 8
        },
        "scale_multiple_10": {
            "showName": "Число 10 параметров",
            "templatesFolder": "componentsCompany/evraz/elementScales/numberMultiple",
            "varsAmount": 10,
            "viewSpan": 8
        },
        "scale_multiple_11": {
            "showName": "Число 11 параметров",
            "templatesFolder": "componentsCompany/evraz/elementScales/numberMultiple",
            "isPayIncrease": true,
            "varsAmount": 11,
            "viewSpan": 8
        }
    }
}