import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        view(payload){
            return requestSender('get', 'matrix/view', payload);
        },
        update(payload){
            return requestSender('post', 'matrix/update', payload);
        },
        delete(payload){
            return requestSender('post', 'matrix/delete', payload);
        },

    }
}