export default {
    "types": {
        "basic": {
            "showName": "Библиотека КПЭ",
            "hideScales": [
                "scale_discrete"
            ]
        },
        "basic_manual_fact": {
            "showName": "Библиотека КПЭ с ручным фактом",
            "hideScales": [
                "scale_discrete"
            ]
        },
        "multiplier": {
            "showName": "Библиотека коэффициентов",
            "columnsConfig": "multiplier_table_columns"
        },
        "multiplier_manual_fact": {
            "showName": "Библиотека коэффициентов с ручным фактом",
            "columnsConfig": "multiplier_table_columns"
        }
    },
    "use_scales_units_library": true,
    "element_change_propagation_mode": 2,
    "element_edit_dialog_width": 80,
    "elements_table_border": true,
    "elements_table_columns": [
        {
            "label": "ID",
            "prop": "id",
            "width": 60
        },
        {
            "label": "Наименование показателя",
            "prop": "name",
            "template": "components/tableColumnTemplates/library/ElementNameTableColumn",
            "width": 300,
            "bind": {
                "scope": "scope",
                "show-folders-in-search": "templateData.$parent.$parent.$data.showFoldersInSearch"
            }
        },
        {
            "label": "Единица измерения",
            "prop": "scale.unit.symbol",
            "width": 100
        },
        {
            "label": "Целевые значения",
            "template": "components/tableColumnTemplates/library/LibraryElementScaleConfigTableColumn",
            "bind": {
                "scope": "scope"
            }
        },
        {
            "label": "Факт",
            "prop": "scale.fact",
            "width": 70
        },
        {
            "label": "Процент премии",
            "prop": "bonus",
            "width": 70
        }
    ],
    "multiplier_table_columns": [
        {
            "label": "ID",
            "prop": "id",
            "width": 50
        },
        {
            "label": "Наименование коэффициента",
            "template": "components/tableColumnTemplates/library/ElementNameTableColumn",
            "width": 300,
            "bind": {
                "scope": "scope",
                "show-folders-in-search": "templateData.$parent.$parent.$data.showFoldersInSearch"
            }
        },
        {
            "label": "Целевые значения",
            "template": "components/tableColumnTemplates/library/LibraryElementScaleConfigTableColumn",
            "bind": {
                "scope": "scope"
            }
        },
        {
            "label": "Факт",
            "prop": "scale.fact",
            "width": 70
        },
        {
            "label": "Итоговое значение",
            "prop": "bonus",
            "width": 90
        }
    ]
}