import requestSender from "./base/requestSender";

export default function (axiosInstance) {
    return {
        search(payload) {
            return requestSender('get', 'group/search', payload);
        },
        save(payload) {
            return requestSender('post', 'group/save', payload);
        },
        delete(payload) {
            return requestSender('post', 'group/delete', payload);
        },

        getCoauthors(payload) {
            return requestSender('get', 'group/get-coauthors', payload);
        },

        addUsersToGroup(payload) {
            return requestSender('post', 'group/assign-users', payload);
        },
        deleteUsersFromGroup(payload) {
            return requestSender('post', 'group/revoke-users', payload);
        },

    }
}