import axios from 'axios';

const checkAppHashUrl = '/version.json';
const defaultCheckFrequency = 1000 * 60 * 2;

export const RefreshClientOnUpdateMixin = {
  data() {
    return {
      checkAppHashUrl: '/version.json',

      currentAppHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
      newAppHash: null,
    }
  },
  methods: {

    initAppVersionCheck(frequency = defaultCheckFrequency) {
      setInterval(() => {
        this.checkVersionChanged();
      }, frequency);
    },

    async checkVersionChanged(){
      // Если тестовое окружение или просто не сработал пост билд
      if( this.currentAppHash.includes('POST_BUILD_ENTERS_HASH_HERE') ){
        return;
      }

      await this.getNewHash();

      if( this.newAppHash !== this.currentAppHash ){
        window.location.reload();
      }
    },

    async getNewHash() {
      const fileResponse = await axios.get(checkAppHashUrl + '?t=' + new Date().getTime());
      this.newAppHash = fileResponse.data.hash;
    },
  }
};