export default {
    "types": {
        "1": {
            "showName": "Создана"
        },
        "10": {
            "showName": "Постановка"
        },
        "50": {
            "showName": "Постановка завершена"
        },
        "60": {
            "showName": "Оценка"
        },
        "200": {
            "showName": "Оценка завершена"
        }
    }
}