<template>
  <div>
    <card-section
      v-for="section in sections"
      :key="section.id"
      :section="section"
      @elements-changed="$emit('elements-changed')"
    >
    </card-section>
  </div>
</template>

<script>


import CardSection from "@/components/cards/CardElementsBlock/CardSection";
import {mapGetters} from "vuex";
export default {
  name: "card-sections",
  components: {CardSection},

  props: {},

  computed: {
    ...mapGetters('card/sections', {
      loading: 'loading',
      sections: 'sections'
    }),
  },
  data() {
    return {
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'card/updateCard') {
        this.$store.dispatch('card/sections/loadSections');
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {

  }
}
</script>




<style>

</style>