<template>

  <el-popover
    placement="top-start"
    trigger="click"
    width="400"

    @show="showed"
    @hide="hidden"
  >
    <el-badge
      :hidden="!notificationsCount"
      slot="reference"
      style="font-size:20px;
                    margin-top:10px;
                    margin-right:20px"
      :value="notificationsCount"
      type="primary"
    >
      <i class="el-icon-bell"></i>
    </el-badge>

    <el-row type="flex" justify="space-between">
      <div><h2 style="margin-top:0">Уведомления</h2></div>
      <div>
          <el-button
              type="text"
              size="small"
              :loading="markAllLoading"
              @click="markAllRead"
          >
            Прочитать все
          </el-button>
      </div>
    </el-row>
    <ul
      class="notification-list"
      v-infinite-scroll="loadNotifications"
    >
      <li
        v-for="notification in notifications"
        class="notification-list-item"
        :class="{seen: notification.read_at}"
      >
        <div style="display: flex;justify-content: space-between">
          <div>{{new Date(notification.created_at).toLocaleString($companyConfiguration.helpers.getDateLocale())}}</div>
          <el-button
            style="padding: 0"
            v-if="!notification.read_at"
            type="text"
            size="mini"
            icon="fas fa-eye"
            @click="markAsRead(notification)"
          >
            Прочитано
          </el-button>
        </div>
        <div v-html="notification.body"></div>
      </li>
    </ul>

    <div
      v-show="!notifications.length"
      align="center"
    >

      Нет уведомлений
    </div>
    <div align="center">
      <el-button
        v-show="loading"
        disabled
        type="text"
        size="mini"
        :loading="loading"
      >Загружаю
      </el-button>
    </div>

  </el-popover>
</template>

<script>


export default {
  name: "notifications",

  props: {
    userId: {type: Number}
  },

  watch: {
    userId: function (newVal) {
      if (!newVal) {
        return;
      }
      this.getCounter();
    }
  },
  data() {
    return {
      loading: false,
      markAllLoading: false,
      page: 1,

      notificationsCount: 0,
      notifications: [],

    }
  },

  mounted() {
    this.getCounter();
    setInterval(this.getCounter, 30000);
  },

  computed: {},
  methods: {
    getCounter() {
      this.$api.notification.getNotificationsCount()
          .then(data => {
              this.notificationsCount = parseInt(data.notifications);
          })
    },

    markAllRead() {
      this.markAllLoading = true;
      this.$api.notification.markAllAsRead()
        .then(data => {
          this.notificationsCount = 0;
          this.notifications.forEach(notify => {
            notify.read_at = 1;
          })
        })
        .finally(() => {
          this.markAllLoading = false;
        })
    },

    markAsRead(notification) {
      notification.read_at = true;
      this.$api.notification.markAsRead({
        id: notification.id,
      })
        .finally(() => {
          this.getCounter();
        })
    },
    loadNotifications() {
      this.loading = true;
      this.$api.notification.getNotifications({
        page: this.page++,
      })
        .then(({data, paginator}) => {
          this.notifications = this.notifications.concat(data.notifications);
          this.paginator = paginator;
        })
        .finally(() => {
          this.loading = false;
        })
    },

    showed() {
      this.notifications = [];
      this.page = 1;
      this.getCounter();
      this.loadNotifications();
    },
    hidden() {
      // this.notifications = [];
      // this.page = 1;
    }
  },

}
</script>
<style>
.notification-list {
  max-height: 400px;
  overflow: auto;

  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.notification-list .notification-list-item {
  padding: 10px 20px;
  margin-right: 3px;
  background-color: rgba(128, 128, 128, 0.1);
  /*background-color: rgba(250, 184, 46, 0.1);*/
  margin-bottom: 20px;
  border: 1px solid rgba(128, 128, 128, 0.39);
}

.notification-list .notification-list-item.seen {
  background-color: white;
  color: rgba(0, 0, 0, 0.4);
}

.notification-list .notification-list-item:last-child {
  margin-bottom: 0;
}
</style>