export default {
    "export": {
        "card": {
            "class": "app\\build\\evraz\\exportImport\\services\\CardExportService",
            "permissions": []
        },
        "library_folder": {
            "class": "app\\build\\evraz\\exportImport\\services\\FolderExportService",
            "permissions": []
        }
    },
    "import": []
}