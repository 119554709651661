import Vue from "vue";
import Vuex from "vuex";

import Api from '../api/base'
import ConstantsPlugin from '../plugins/constants'
import router from '@/router'

import cardModule from './modules/card'
import settingsModule from './modules/settings'

Vue.use(Vuex);
Vue.use(ConstantsPlugin);



export default new Vuex.Store({
    state: {
        loadingLogin: false,
        user: {
            id: null,
            permissions: [],
            chief: {}
        },
    },
    getters: {
        user(state) {
            return state.user
        },
        loadingLogin(state) {
            return state.loadingLogin
        },

    },
    actions: {
        fetchUser(ctx) {
            Api.user.getAuthUser({
                expand: 'permissions, roles'
            })
                .then(data => {
                    ctx.commit('updateUser', data.user);
                })
        },
        logoutUser(ctx) {
            localStorage.removeItem('jwt');
            localStorage.removeItem('refresh_token');
            ctx.commit('updateUser', {});
        },

        setLoadingLogin(ctx, isLoading) {
            ctx.commit('setLoadingLogin', isLoading);
        },


    },


    mutations: {
        setLoadingLogin(state, isLoading){
            state.loadingLogin = isLoading;
        },
        updateUser(state, user) {
            state.user = user;
        },
    },

    modules: {
        card: cardModule,
        settings: settingsModule,
    }
})