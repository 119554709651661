<template>
  <el-dialog
    :title="role.name ? 'Редактирование роли' : 'Создание роли'"
    :visible.sync="show"
    width="70%"
  >
    <el-row>
      <el-form
        ref="roleEditForm"
        size="small"
        label-position="top"
        :model="role"
        :rules="roleRules"
      >
        <el-form-item label="Название роли" prop="description">
          <el-input maxlength="100" show-word-limit v-model="role.description"></el-input>
        </el-form-item>
      </el-form>
    </el-row>

    <div slot="footer" class="dialog-footer">
      <el-popconfirm
        v-if="role.can_delete && $canAndRulesPass(user, $permissions.RBAC.ROLE_DELETE)"
        style="float:left"
        v-show="role.name"
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите удалить роль?"
        @confirm="deleteRole"
      >
        <el-button
          slot="reference"
          type="danger"
          size="small"
          plain
          icon="fas fa-trash"
          :loading="loading"
        >
          Удалить роль
        </el-button>
      </el-popconfirm>
      <el-button size="small" @click="show = false">Закрыть</el-button>
      <el-button
        type="primary"
        size="small"
        :loading="loading"
        :disabled="!role.description"
        @click="save"
      >
        Сохранить
      </el-button>
    </div>
  </el-dialog>
</template>

<script>


import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator";
import UsersTable from "@/components/user/UsersTable";
import {mapGetters} from "vuex";

export default {
  name: "rbac-role-edit-dialog",
  mixins: [paginator],
  components: {UsersTable, Paginator},
  props: {},
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      show: false,
      loading: false,

      role: {},
      roleRules: {
        description: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
      },
    }
  },
  mounted() {

  },
  methods: {
    open(role) {
      this.role = Object.assign({}, role);
      this.show = true;
    },
    close() {
      this.show = false;
    },

    save() {
      this.$refs.roleEditForm.validate((result) => {
        if (!result) {
          return;
        }

        this.loading = true;
        this.$api.rbac.saveRole({
          name: this.role.name,
          description: this.role.description,
        })
          .then(data => {
            this.$notify.success({
              title: 'Сохранено',
              message: 'Роль успешно сохранена'
            });
          })
          .finally(() => {
            this.loading = false;
            this.show = false;
            this.$emit('role-updated');
          })
      });
    },
    deleteRole() {
      this.loading = true;
      this.$api.rbac.deleteRole({
        name: this.role.name
      })
        .then(data => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Роль успешно удалена'
          });
        })
        .finally(() => {
          this.loading = false;
          this.show = false;
          this.$emit('role-updated');
        })
    },

  }
}
</script>

<style>

</style>