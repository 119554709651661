export default {
    "enabled_auth_methods": [
        "loginPass"
    ],
    "auth_methods": {
        "loginPass": [],
        "openId": {
            "domain": "",
            "clientId": "",
            "redirectUri": "",
            "service": "app\\build\\evraz\\auth\\services\\AuthOpenIdService",
            "loginRequestClass": "app\\build\\evraz\\auth\\requests\\AuthOpenIdLoginRequest",
            "authField": "email",
            "template": "componentsCompany/evraz/auth/openId"
        }
    }
}