<template>
  <div class="home-charts-container">

    <cards-page-filters
      v-model="filters"
      :hidden="['search', 'stages', 'negotiations', 'my_turn', 'my']"
      @change=""
    >
    </cards-page-filters>

    <el-row style="margin-top: 25px">
      <el-col :span="12" v-if="$canAndRulesPass(user, 'main_page.see_graph_plan')">
        <div class="chart-name">Постановка целей</div>
        <home-chart
          :stages="['plan']"
          :filters="filters"
        ></home-chart>
      </el-col>
      <el-col :span="12" v-if="$canAndRulesPass(user, 'main_page.see_graph_assessment')">
        <div class="chart-name">Подведение итогов</div>
        <home-chart
          :stages="['assessment']"
          :filters="filters"
        ></home-chart>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import {Chart} from 'highcharts-vue'
import HomeChart from "@/components/home/HomeChart.vue";
import CardsPageFilters from "@/components/cards/CardsPageFilters";
import {mapGetters} from "vuex";

export default {
  name: "home-charts",
  components: {CardsPageFilters, HomeChart, highcharts: Chart},

  props: {},
  computed: {
    ...mapGetters(['user']),
  },

  data() {
    return {
      loading: false,
      filters: {},

      counters: {},

    }
  },
  mounted() {

  },
  watch: {},
  methods: {

  }
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.home-charts-container{

  .chart-name {
    text-align: center;
    font-weight: bold;
  }
}

</style>