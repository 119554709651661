<template>

  <div>
    Последние задачи
    <el-table
      style="width: 100%"
      :data="jobs"
      empty-text="Нет созданных задач"
      v-loading="loading"
    >
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column label="Создана">
        <template v-slot="scope">
          {{ new Date(scope.row.pushed_at * 1000).toLocaleDateString($companyConfiguration.helpers.getDateLocale()) }}
        </template>
      </el-table-column>
      <el-table-column label="Выполнена">
        <template v-slot="scope">
          {{ scope.row.done_at ? new Date(scope.row.done_at * 1000).toLocaleDateString($companyConfiguration.helpers.getDateLocale()) : 'Не выполнена' }}
        </template>
      </el-table-column>
    </el-table>


  </div>


</template>

<script>


export default {
  name: "Jobs",
  components: {},
  props: {},
  computed: {},

  data() {
    return {
      loading: false,
      jobs: [],
    }
  },
  mounted() {
    this.getLastJobs()
  },
  methods: {
    getLastJobs(){
      this.$api.massAction.jobs()
        .then(data => {
          this.jobs = data.jobs;
        })
    },
  }
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";


</style>