import Login from "@/views/Login";
import Library from "@/views/Library";
import Cards from "@/views/Cards";
import Card from "@/views/Card";
import Report from "@/views/Report";
import MassActions from "@/views/MassActions";
import Groups from "@/views/Groups";
import Matrix from "@/views/Matrix";
import Home from "@/componentsCompany/evraz/views/Home.vue";
import DynamicSettings from "@/views/settings/DynamicSettings.vue";
import Chiefs from "@/views/settings/Chiefs.vue";
import Rbac from "@/views/settings/Rbac.vue";
import Scales from "@/views/settings/Scales.vue";


export default [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {title: 'Домашняя страница'}
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {title: 'Логин', layout: 'empty-layout'}
    },
    {
        path: '/library/:type([\\w]+)',
        name: 'Library',
        component: Library,
        meta: {title: 'Библиотека'}
    },
    {
        path: '/cards',
        name: 'Cards',
        component: Cards,
        meta: {title: 'Карты'}
    },
    {
        path: '/card/:id(\\d+)',
        name: 'Card',
        component: Card,
        meta: {title: 'Карта'}
    },
    {
        path: '/report',
        name: 'Report',
        component: Report,
        meta: {title: 'Конструктор отчетов'}
    },
    {
        path: '/matrix',
        name: 'Matrix',
        component: Matrix,
        meta: {title: 'Навигатор по целям подчинённых'}
    },



    {
        path: '/settings/dynamic',
        name: 'DynamicSettings',
        component: DynamicSettings,
        meta: {title: 'Настройки'}
    },
    {
        path: '/settings/chiefs',
        name: 'Chiefs',
        component: Chiefs,
        meta: {title: 'Настройка орг. структуры'}
    },
    {
        path: '/settings/rbac',
        name: 'Rbac',
        component: Rbac,
        meta: {title: 'Настройка ролей'}
    },
    {
        path: '/settings/scales',
        name: 'Scales',
        component: Scales,
        meta: {title: 'Настройка шкал'}
    },
    {
        path: '/settings/groups',
        name: 'Groups',
        component: Groups,
        meta: {title: 'Настройка групп'}
    },
    {
        path: '/settings/mass-actions',
        name: 'MassActions',
        component: MassActions,
        meta: {title: 'Массовые действия'}
    },

]
