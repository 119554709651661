<template>
  <el-dialog
    :title="negotiation.id ? 'Редактирование шага' : 'Создание шага'"
    append-to-body
    :visible.sync="show"
    width="40%"
  >

    <negotiation-edit-form
      ref="addNegotiationForm"
      v-loading="loading"
      v-model="negotiation"
    ></negotiation-edit-form>

    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">Отмена</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        @click="saveNegotiation"
      >Сохранить
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import AddUsersDialog from "@/components/user/AddUsersDialog";
import NegotiationEditForm from "@/components/cards/CardStagesBlock/NegotiationEditForm.vue";
export default {
  name: "negotiation-edit-dialog",
  components: {NegotiationEditForm, AddUsersDialog},

  props: {},

  computed: {},
  watch: {
    'negotiation.user': function (newVal) {
      this.negotiation.user_id = newVal ? newVal.id : null;
    },
  },
  data() {
    return {
      show: false,
      loading: false,

      negotiation: {
        type: null,
        description: null,
        user: null,
        user_id: null,
      },
      negotiationRules: {
        type: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
      },
    }
  },
  methods: {
    open(negotiation={}) {
      if( Object.keys(negotiation).length){
        this.negotiation = Object.assign({}, negotiation);
      } else {
        this.negotiation = {type: null, user: null}
      }

      this.loading = false;
      if( this.$refs.addNegotiationForm && this.$refs.addNegotiationForm.$refs.addNegotiationForm ){
        this.$refs.addNegotiationForm.$refs.addNegotiationForm.resetFields();
      }
      this.show = true;
    },
    close() {
      this.show = false;
    },

    userSelected(user){
      this.negotiation.user_id = user.id;
      this.negotiation.user = user;
    },

    saveNegotiation() {
      this.$refs.addNegotiationForm.$refs.addNegotiationForm.validate((result)=>{
        if( !result ){
          return;
        }
        let negotiation = structuredClone(this.negotiation);
        this.$emit('negotiation-saved', negotiation);
        this.close();
      });
    },

  }
}
</script>

<style>

</style>