import requestSender from "./base/requestSender";

export default function (axiosInstance) {
    return {
        getBlocksByParent(payload) {
            return requestSender('get', 'report/get-blocks-by-parent', payload);
        },
        buildReport(payload) {
            return requestSender('post', 'report/build-report', payload);
        },


        exportReportToXls(payload, options){
            return requestSender('post', 'report/report-to-xls', payload, options);
        },

        getSavedReports(payload) {
            return requestSender('get', 'report/get-saved-reports', payload);
        },
        saveReport(payload) {
            return requestSender('post', 'report/save-report', payload);
        },
        deleteReport(payload) {
            return requestSender('post', 'report/delete-report', payload);
        },


        getVisualQueryBuilderForm(payload) {
            return requestSender('get', 'visual-query-builder/get-builder-form', payload);
        },
        saveVisualQueryBuilderForm(payload) {
            return requestSender('post', 'visual-query-builder/assign-form', payload);
        },
    }
}