<template>
    <div>
        <page-heading
            :heading="$companyConfiguration.libraries.getTypeShowName($route.params.type)"
        >
            <span slot="help"></span>
            <span slot="right"></span>
        </page-heading>

        <el-card>
            <library-manager
              :type="$route.params.type"
            ></library-manager>
        </el-card>
    </div>

</template>

<script>

import PageHeading from "@/components/PageHeading";
import LibraryManager from "@/components/library/LibraryManager";

export default {
    name: 'Kpi',
    components: {LibraryManager, PageHeading },

    computed: {

    },

    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {}
}

</script>

<style>

</style>