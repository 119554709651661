import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {

        getFoldersByParent(payload){
            return requestSender('get', 'library/get-folders-by-parent', payload);
        },
        saveFolder(payload){
            return requestSender('post', 'library/save-folder', payload);
        },
        deleteFolder(payload){
            return requestSender('post', 'library/delete-folder', payload);
        },
        duplicateFolder(payload){
            return requestSender('post', 'library/duplicate-folder', payload);
        },
        exportFolder(payload, options){
            return requestSender('post', 'library/export-folder', payload, options);
        },


        getFolderSavedBuilder(payload){
            return requestSender('get', 'library/get-folder-saved-builder', payload);
        },
        saveFolderSavedBuilder(payload){
            return requestSender('post', 'library/save-folder-saved-builder', payload);
        },



        importElements(payload){
            return requestSender('post', 'library/import-folder', payload);
        },



        searchElements(payload){
            return requestSender('get', 'library/search-elements', payload);
        },
        saveElement(payload){
            return requestSender('post', 'library/save-element', payload);
        },
        deleteElement(payload){
            return requestSender('post', 'library/delete-element', payload);
        },
        getElementHistory(payload){
            return requestSender('get', 'library/get-element-history', payload);
        },


        getScalesUnits(payload){
            return requestSender('get', 'library/get-scales-units', payload);
        },
        saveScalesUnit(payload){
            return requestSender('post', 'library/save-scales-unit', payload);
        },
        deleteScalesUnit(payload){
            return requestSender('post', 'library/delete-scales-unit', payload);
        },







    }
}