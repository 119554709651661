<template>
  <div v-loading="loading">
    <highcharts ref="chart" :options="chartOptions"></highcharts>


    <el-dialog
      :visible.sync="cardsModal.show"
      top="2vh"
      width="50%"
    >
      <el-table
        v-loading="cardsModal.loading"
        size="mini"
        empty-text="Нет карт"
        :data="cardsModal.cards"
      >
        <el-table-column prop="user.full_name" label="ФИО">
          <template slot-scope="scope">
            {{ scope.row.user.full_name }}
            <el-button
              type="text"
              size="mini"
              icon="fas fa-external-link-alt"
              @click="openCardInNewWindow(scope.row.id)"
            >

            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <paginator
        :paginator.sync="paginator"
        @page-changed="loadSectorData"
      ></paginator>


      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cardsModal.show = false">Закрыть</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {Chart} from 'highcharts-vue'
import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator.vue";

export default {
  name: "home-chart",
  components: {Paginator, highcharts: Chart},
  mixins: [paginator],

  props: {
    stages: {type: Array},
    filters: {type: Object},
  },
  computed: {},

  data() {
    return {
      loading: false,

      cardsModal: {
        show: false,
        loading: false,
        currentSector: null,
        cards: [],
      },

      chartOptions: {
        chart: {
          type: 'pie'
        },
        credits: {enabled: false},
        title: false,
        tooltip: {pointFormat: '<b>{point.y}</b>'},
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              style: {fontSize: '1rem'},
              format: '<b>{point.name}</b>: {point.y}'
            }
          }
        },

        series: [{
          shadow: true,
          events: {
            click: (event) => {
              this.sectorClicked(event.point);
            }
          },
          data: []
        }]
      }
    }
  },
  mounted() {

  },
  watch: {
    filters: {
      handler(val){
        this.loadChartData();
      },
      deep: true
    }
  },
  methods: {
    loadChartData() {
      this.loading = true;
      this.$api.home.getChartData({
        stages_to_filter: this.stages,
        ...this.filters,
      })
        .then(data => {
          this.$refs.chart.chart.series[0].setData(
            [
              {color: '#fab82e', type: 'self', name: 'Сотрудник', y: data.self_count},
              {color: '#F57F29', type: 'chief', name: 'Руководитель', y: data.chief_count},
              {color: '#e56304', type: 'other', name: 'Прочее', y: data.other_count},
              {color: '#858585', type: 'finished', name: 'Завершено', y: data.finished_count},

            ], true);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    sectorClicked(sector){
      this.cardsModal.currentSector = sector;
      this.paginator.page = 1;
      this.loadSectorData();
    },

    loadSectorData() {
      this.cardsModal.show = true;
      this.cardsModal.loading = true;
      this.$api.home.getChartData({
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        stages_to_filter: this.stages,
        sector: this.cardsModal.currentSector.type,
        ...this.filters,
        expand: 'user',
      })
        .then(({data, paginator}) => {
          this.paginatorFill(paginator);
          this.cardsModal.cards = data.cards;
        })
        .finally(() => {
          this.cardsModal.loading = false;
        })
    },

    openCardInNewWindow(cardId) {
      let route = this.$router.resolve({name: 'Card', params: {id: cardId}});
      window.open(route.href);
    },

  }
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";


</style>